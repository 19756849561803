<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增/修改工序汇报组件
	开始时间：2021-05-17
	开发人员：刘巍骏
	最后修改：2021-05-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <!-- 汇报工序表单数据 -->

    <el-dialog :title="checkView!=1?'新增汇报':operateType==2?'报工返工':'报工验收'" :visible.sync="show_report" width="600px" :before-close="cancleReport"
      :close-on-click-modal="false" append-to-body>
      <el-scrollbar style="height:500px;">
      <div class="billing_dialog_form"  id="createReportBox" >
        <!-- 基本信息 -->
        <div class="form_typeBox">
          <!-- 分类提示信息 -->
          <h5 class="form_type">基本信息</h5>
          <!-- 内容 -->
          <ul class="form_ul">
            <li>
              <div class="title">
                <span class="titleText">工序名称:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.processName" size="small" :disabled="true" placeholder="请输入内容">
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">计费方式:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.costTypeName" size="small" :disabled="true" placeholder="请输入内容">
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">合计用时:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.dispatchNum" size="small" :disabled="true" placeholder="请输入内容">
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">派工数量:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.workNum" @change="residualFraction()" size="small" :disabled="viewChanges==1"  placeholder="请输入内容">
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">合格数量:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.qualifiedAllNum" size="small" :disabled="true" placeholder="请输入内容">
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">剩余数量:</span>
              </div>
              <div class="data">
                <el-input v-model="report_baseData.residueNum" size="small" :disabled="true" placeholder="请输入内容">
                </el-input>
              </div>
            </li>
          </ul>
        </div>
        <!-- 汇报信息 -->
        <div class="form_typeBox">
          <h5 class="form_type">汇报信息</h5>
          <ul class="form_ul">
            <li>
              <div class="title">
                <span class="titleText">加工人员:</span>
              </div>
              <div class="data" @click="getUserData(0)">
                <el-input v-model="report_contData.operated_user" :disabled="viewChanges==1" oninput="this.value=this.value.replace(/\S+/g,'')"
                  size="small" placeholder="请输入内容">
                  <i slot="suffix" class="el-input__icon el-icon-more"></i>
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">检验人员:</span>
              </div>
              <!-- <div class="data"   @click="getUserData(1)"> -->
              <div class="data">
                <el-input v-model="reportProcess.userName"  disabled oninput="this.value=this.value.replace(/\S+/g,'')"
                  size="small" placeholder="请输入内容">
                  <i slot="suffix" class="el-input__icon el-icon-more"></i>
                </el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">合格数量:</span>
              </div>
              <div class="data">
                <el-input v-model="report_contData.qualified_num" :disabled="viewChanges==1||operateType==1||operateType==2" size="small" placeholder="请输入内容"></el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">报废数量:</span>
              </div>
              <div class="data">
                <!-- <el-input v-model="report_contData.scrap_num" :disabled="viewChanges==1" size="small" placeholder="请输入内容"></el-input> -->
                <el-input v-model="report_contData.scrap_num" disabled size="small" placeholder="请输入内容"></el-input>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">开始时间:</span>
              </div>
              <div class="data">
                <el-date-picker class="data_input" size="small" :disabled="viewChanges==1" @input="getTimeFormat($event,'start_time')"
                  v-model="report_contData.start_time" @change="computationTime()" type="datetime" placeholder="选择日期"></el-date-picker>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">结束时间:</span>
              </div>
              <div class="data">
                <el-date-picker class="data_input" size="small" :disabled="viewChanges==1" @input="getTimeFormat($event,'end_time')"
                  v-model="report_contData.end_time" @change="computationTime()" type="datetime" placeholder="选择日期"></el-date-picker>
              </div>
            </li>
            <li>
              <div class="title">
                <span class="titleText">合计用时(h):</span>
              </div>
              <div class="data">
                <el-input v-model="report_contData.total_time"  disabled size="small" placeholder="请输入内容"></el-input>
              </div>
            </li>
            <li class="single_li">
              <div class="title">
                <span class="titleText">备注:</span>
              </div>
              <div class="data">
                <el-input type="textarea" :rows="2" :disabled="viewChanges==1" placeholder="请输入内容" v-model="report_contData.remark"></el-input>
              </div>
            </li>
          </ul>
        </div>
        <div class="form_typeBox">
        <h5 class="form_type">不良品项</h5>
          <template>
            <!-- 报废使用 -->
            <el-table size="small"
              :data="processRegects"
              border
              style="width: 100%"
              v-if="operateType!=2">
              <el-table-column
                prop="name"
                label="名称"
                width="150">
              </el-table-column>
              <el-table-column
                prop="number"
                label="数量"
                width="150">
                <template slot-scope="scope">
                  <el-input :disabled="viewChanges==1" v-model="scope.row.number" @change="calculationScrap()" size="small" placeholder="请输入数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="处理方式"
                width="200" >
                <template slot-scope="scope">
                  <span>报废</span>
                </template>
              </el-table-column>
            </el-table>
            <!-- 返工使用 -->
            <el-table size="small"
              :data="processRegects"
              border
              style="width: 100%"
              v-if="operateType==2">
              <el-table-column
                prop="name"
                label="名称"
                width="150">
              </el-table-column>
              <el-table-column
                prop="number"
                label="数量"
                width="150">
                <template slot-scope="scope">
                  <el-input  v-model="scope.row.number" @change="calculationRework()" size="small" placeholder="请输入数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="处理方式"
                width="200">
                <template slot-scope="scope">
                  <span>返工</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
      </div>
      </div>
      </el-scrollbar>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commitReport(0)" v-if="checkView!=1" >提交汇报</el-button>
        <el-button type="primary" size="mini" @click="reportForAcceptance()" v-if="checkView==1 &&viewChanges!=1 &&operateType!=2">直接验收</el-button>
        <el-button type="primary" size="mini" @click="commitReport(1)" v-if="checkView==1 &&viewChanges!=1 &&operateType!=2 ">修改并保存</el-button>
        <el-button type="primary" size="mini" @click="reworkProposal()" v-if="operateType==2">返工</el-button>
        <!-- <el-button type="primary" size="mini" @click="commitReport(1)">提交并完成</el-button> -->
        <el-button size="mini" @click="cancleReport">取 消</el-button>
      </span>

    </el-dialog>

    <!-- 负责人选择弹框组件 -->
    <personnelList @SelectedData="getSelUserData" v-if="show_personneList"></personnelList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import departmentTree from "@/components/commComponent/tree/tree_department.vue"; //部门组件
  import personnelList from "@/components/commComponent/list/list_personnel.vue"; //负责人组件

  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口

  export default {
    name: 'report_create',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      },
      //生产单详情数据
      viewChanges: {
        type: Number,
        default: 0
      },
      //当前汇报的工序数据
      reportProcess: {
        type: Object,
        default () {
          return {}
        }
      },
      //当前汇报的工序数据
      checkView: {
        type: Number,
        default: 0
      },
      //需要回显的工序汇报数据
      reportDetailData: {
        type: Object,
        default () {
          return {}
        }
      },
      // 操作方式(0:新增  1:修改)
      operateType: {
        type: Number,
        default: -1
      },
      //控制显示工序汇报弹框
      show_report: {
        type: Boolean,
        default: false
      },
      //不良品项
      processRegects: {
        type: Array,
        default () {
          return []
        }
      },
    },
    data() {
      return {
        //界面数据
        report_baseData: { //汇报基本信息
          processName: '', //工序名称
          costTypeName:'',//计费方式
          workNum:'',//派工数量
          dispatchNum: '', //合计用时
          qualifiedAllNum: '', //总合格数量
          residueNum: '', //剩余数量
        },
        report_contData: { //汇报内容信息
          operated_user: '', //加工人员名称字符串
          operated_userId: '', //加工人员id字符串
          check_user: '', //检验人员
          check_userId: '', //检验人员id字符串
          qualified_num: '', //合格数量
          scrap_num: '', //报废数量
          start_time: '', //开始时间
          end_time: '', //结束时间
          total_time: '', //合计用时
          remark: '', //备注
        },
        userType: 0, //获取员工信息类型(0:加工人员  1:检验人员)

        //控制开关
        show_personneList: false, //控制加载负责人弹框

        //loading框
        loading: '', //loading框

        //定义一个老的不良品项
        oldProcessRegects:[],
        oldreportBaseData:{},//老的基本信息
        oldreportContData:{},//老的汇报信息
        oldyanshouProcessRegects:[],//汇报使用的老的不良信息

        //记录返工一开始进来的合格数量
        oldQualifiedNum:0,

      }
    },
    watch: {
      //监听工序弹框显示
      show_report(newVal) {
        if (newVal) {
          //初始化汇报数据
          this.initReportData();
        }
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制员工弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_TECHNOLOGYBOX', //控制工序选择弹框
      ]),

      /* 汇报弹框显示是初始化数据 */
      initReportData() {
        // 判断是新增还是修改
        if (this.operateType == 0) { //新增
          this.getAddProcessData();
        } else if (this.operateType == 1) { //修改
          this.oldProcessRegects=JSON.parse(JSON.stringify(this.processRegects))
          this.oldyanshouProcessRegects=JSON.parse(JSON.stringify(this.processRegects))
          this.oldreportBaseData=Object.assign({},this.reportProcess)
          this.oldreportContData=Object.assign({},this.reportDetailData)
          console.log(this.oldreportBaseData)
          console.log(this.oldreportContData)
          this.getUpdateProcessData();
        }else if(this.operateType==2){//返工
        this.oldProcessRegects=JSON.parse(JSON.stringify(this.processRegects))
        this.oldreportBaseData=JSON.parse(JSON.stringify(this.reportProcess))
        this.oldyanshouProcessRegects=JSON.parse(JSON.stringify(this.processRegects))
        this.oldreportContData=JSON.parse(JSON.stringify(this.reportDetailData))
          this.getUpdateProcessData();
        }
      },

      /* 显示工序汇报弹框信息 */
      getAddProcessData() {
        /* 初始化汇报基本信息 */
        this.report_baseData = {
          processName: this.reportProcess.name, //工序名称
          dispatchNum: this.reportProcess.totalTime, //合计用时
          costTypeName:this.reportProcess.costTypeName,//计费方式
          workNum:this.reportProcess.workNum,//派工数量
          qualifiedAllNum: this.reportProcess.qualifiedNum, //总合格数量
          residueNum: this.reportProcess.residueNum, //剩余数量
        }
        /* 初始化汇报信息 */
        this.report_contData = {
          operated_user: '', //加工人员
          operated_userId: '', //加工人员id
          check_user: '', //检验人员
          check_userId: '', //检验人员id
          qualified_num: 0, //合格数量
          scrap_num: 0, //报废数量
          start_time: '', //开始时间
          end_time: '', //结束时间
          total_time: 0, //合计用时
          remark: '', //备注
        }
      },
      //当派单数改变的时候计算剩余数量
      residualFraction(){
        this.report_baseData.residueNum=this.report_baseData.workNum-this.report_baseData.qualifiedAllNum
      },

      /*计算开始时间和结束时间的时间差*/
      computationTime(){
        if(!!!this.report_contData.start_time){
          this.$message({
            type: "warning",
            message: "请先选择开始时间",
            duration: this.elDuration
          })
          //将结束时间置空
          this.report_contData.end_time=''
          return
        }
        else{
          let  dateBegin = new Date(this.report_contData.start_time);
          let  dateEnd = new Date(this.report_contData.end_time);
          let  dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差毫秒
          if(dateDiff<0){
            this.$message({
              type: "warning",
              message: "开始时间不能大于结束时间",
              duration: this.elDuration
            })
            //将时间置空
            this.report_contData.end_time=''
            this.report_contData.start_time=''
            this.report_contData.total_time=''
            return
          }
          if(!!this.report_contData.start_time && !!this.report_contData.end_time){
            this.report_contData.total_time=Math.floor(dateDiff / (3600 * 1000)); //计算出小时数
          }


        }

      },
      //计算报废数量
      calculationScrap(){
        let totalNum=this.report_contData.scrap_num+this.report_contData.qualified_num//总数量
        let oldScrapNum=this.report_contData.scrap_num//老的报废数量
        this.report_contData.scrap_num=0
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            this.report_contData.scrap_num=this.report_contData.scrap_num+Number(item.number)
          }
        })

        if(this.checkView==1){//当为验收查看的时候
        if( this.report_contData.scrap_num>totalNum){
          this.$message({
            type:"warning",
            message:"报废数已大于汇报总数,请确认"
          })
          this.processRegects.forEach((item,index)=>{
           item.number=this.oldProcessRegects[index].number
          })
          this.report_contData.scrap_num=oldScrapNum
          return
        }
        this.oldProcessRegects=JSON.parse(JSON.stringify(this.processRegects))
        //修改相关报废数量的时候 更改相关的合格数量
          if(oldScrapNum<this.report_contData.scrap_num){
            this.report_contData.qualified_num=this.report_contData.qualified_num-(this.report_contData.scrap_num-oldScrapNum)
          }else{
            this.report_contData.qualified_num=this.report_contData.qualified_num+(oldScrapNum-this.report_contData.scrap_num)
          }
        }
      },

      //返工相关计算
      calculationRework(){
        if(this.oldQualifiedNum==0){
          this.oldQualifiedNum=this.report_contData.qualified_num//老的合格数量
        }
        console.log(this.oldQualifiedNum)
        this.report_contData.qualified_num=this.oldQualifiedNum
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            this.report_contData.qualified_num=this.report_contData.qualified_num-Number(item.number)
          }
        })
        if(this.report_contData.qualified_num<0){
          this.$message({
            type:"warning",
            message:"返工数量大于合格数量 请确认"
          })
          this.processRegects.forEach((item,index)=>{
           item.number=''
          })
          this.report_contData.qualified_num=this.oldQualifiedNum
          return
        }
      },

      /* 获取修改的回显信息 */
      getUpdateProcessData() {
        /* 初始化汇报基本信息 */
        this.report_baseData = {
          processName: this.reportProcess.name, //工序名称
          dispatchNum: this.reportProcess.totalTime, //合计用时
          costTypeName:this.reportProcess.costTypeName,//计费方式
          workNum:this.reportProcess.workNum,//派工数量
          qualifiedAllNum: this.reportProcess.qualifiedNum, //总合格数量
          residueNum: this.reportProcess.residueNum, //剩余数量
        }
        /* 初始化汇报信息 */
        this.report_contData = {
          operated_user: this.reportDetailData.operated_user_name, //加工人员
          operated_userId: this.reportDetailData.operated_user, //加工人员id
          check_user: this.reportDetailData.check_user_name, //检验人员
          check_userId: this.reportDetailData.check_user, //检验人员id
          qualified_num: this.reportDetailData.qualified_num, //合格数量
          scrap_num: this.reportDetailData.scrap_num, //报废数量
          start_time: this.reportDetailData.start_time, //开始时间
          end_time: this.reportDetailData.ent_time, //结束时间
          total_time: this.reportDetailData.total_time, //合计用时
          remark: this.reportDetailData.remark, //备注
        }

      },

      /* 获取时间格式 */
      getTimeFormat(date,filed) {
        this.report_contData[filed] = this.commonJsExtend.getDateTime(date, 2);
      },

      /* 获取员工选择弹框数据 */
      getSelUserData(data) {
        //获取所选员工信息
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //判断获取哪种员工
        if (this.userType == 0) { //加工人员
          this.report_contData.operated_user = result.nameShowStr;
          this.report_contData.operated_userId = result.idStr;
        } else if (this.userType == 1) { //验收人员
          this.report_contData.check_user = result.nameShowStr;
          this.report_contData.check_userId = result.idStr;
        }
      },

      /* 取消提交工序汇报 */
      cancleReport() {
        this.$parent.closeReportCreateBox();
      },

      /* 获取加工人员,验收人员信息type(0:加工人员  1:验收人员) */
      getUserData(type) {
        //将操作类型存入data
        this.userType = type;
        //加载负责人弹框
        this.show_personneList = true;
        //显示员工选择弹框
        this.SHOW_PERSONNELBOX(true);
      },
      reportForAcceptance(){
        let regectName=[] //报废名称
        let regectNumber=[] //报废数量
        let oldregectName=[] //原报废名称
        let oldregectNumber=[] //原报废数量
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            regectName.push(item.name)
            regectNumber.push(item.number)
          }
        })
        this.oldyanshouProcessRegects.forEach((item,index)=>{
          if(!!item.number){
            oldregectName.push(item.name)
            oldregectNumber.push(item.number)
          }
        })
        let mesData={
          id:this.reportDetailData.id
        }
        //定义传入后端的数据
        let data = {
          mes_main_id: this.mesDetailData.id, //生产单id
          process_id: this.reportProcess.id, //工序id
          process_name:this.reportProcess.name,//工序名称
          check_user: this.report_contData.check_userId, //检验人员
          check_user_name: this.report_contData.check_user, //检验人员
          operated_user: this.report_contData.operated_userId, //加工人员
          operated_user_name: this.report_contData.operated_user, //加工人员
          work_num:this.report_baseData.workNum,//派工数量
          total_time:this.report_baseData.dispatchNum,//合计用时
          qualified_num: this.report_contData.qualified_num, //合格数量
          residue_num: this.report_baseData.residueNum, //剩余数量
          scrap_num: this.report_contData.scrap_num, //报废数量
          total_time: this.report_contData.total_time, //合计用时
          start_time: this.report_contData.start_time, //开始时间
          ent_time: this.report_contData.end_time, //结束时间
          remark: this.report_contData.remark, //备注
          mesProcessRejects:JSON.stringify(this.processRegects),//不良品项相关报废数
        }
        console.log(this.oldreportContData)
        //操作日志
        let log="[原报工验收信息为:派工数量为:"+this.oldreportBaseData.workNum+",加工人员:"+this.oldreportContData.operated_user_name
        +",合格数量:"+this.oldreportContData.qualified_num+",报废数量:"+this.oldreportContData.scrap_num+",原因:"
        +oldregectName.join(",")+",数量:"+oldregectNumber.join(",")+",开始时间:"+this.oldreportContData.start_time
        +",结束时间:"+this.oldreportContData.ent_time+",合计用时:"+this.oldreportContData.total_time+",备注:"+this.oldreportContData.remark
        +"]"+"[现报工验收信息为:派工数量为:"+this.report_baseData.workNum+",加工人员:"+this.report_contData.operated_user
        +",合格数量:"+this.report_contData.qualified_num+",报废数量:"+this.report_contData.scrap_num+",原因:"
        +regectName.join(",")+",数量:"+regectNumber.join(",")+",开始时间:"+this.report_contData.start_time
        +",结束时间:"+this.report_contData.end_time+",合计用时:"+this.report_contData.total_time+",备注:"
        +this.report_contData.remark+"]进行要验收操作"
        mesData.logOper=log
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#createReportBox", 4, '相关数据修改中,请稍候...');
        //获取需要修改的数据id
        data.id = this.reportDetailData.id;
        // 发送请求
        api.updateMesMainProcessRecord(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
          } else {
            this.$message({
              type: "error",
              message: res.message,
              duration: this.elDuration
            })
          }
        })
        this.$confirm("本次报工合格数为:"+this.report_contData.qualified_num+ "；报废数为:"+this.report_contData.scrap_num+"；合计用时:"+this.report_contData.total_time+"，确认本次报工合格后，本次数据将不能更改。", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          api.reportForAcceptance(mesData).then(res=>{
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '验收成功',
                duration: this.elDuration
              })
              this.cancleReport();
            } else {
              this.$message({
                type: 'error',
                message: res.data,
                duration: this.elDuration
              })
              return
            }
          })
        }).catch(()=>{
           this.$message({
             type: 'info',
             message: '已取消验收操作',
             duration: this.elDuration
           });
         })
      },

      //返工
      reworkProposal(){
        let regectName=[] //返工名称
        let regectNumber=[] //返工数量
        let oldregectName=[] //原报废名称
        let oldregectNumber=[] //原报废数量
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            regectName.push(item.name)
            regectNumber.push(item.number)
          }
        })
        this.oldyanshouProcessRegects.forEach((item,index)=>{
          if(!!item.number){
            oldregectName.push(item.name)
            oldregectNumber.push(item.number)
          }
        })
        let num=0;
        this.processRegects.forEach((item,index)=>{
          if(!!!item.number){
            num=num+1
          }
        })
        if(num===this.processRegects.length){
          this.$message({
            type: 'warning',
            message: '请填写相关返工数量',
            duration: this.elDuration
          })
          return
        }
        let dataStute={
          id:this.reportDetailData.id,
          mesProcessRejects:JSON.stringify(this.processRegects),//不良品项相关报废数
        }
        //定义传入后端的数据
        let data = {
          mes_main_id: this.mesDetailData.id, //生产单id
          process_id: this.reportProcess.id, //工序id
          process_name:this.reportProcess.name,//工序名称
          check_user: this.report_contData.check_userId, //检验人员
          check_user_name: this.report_contData.check_user, //检验人员
          operated_user: this.report_contData.operated_userId, //加工人员
          operated_user_name: this.report_contData.operated_user, //加工人员
          work_num:this.report_baseData.workNum,//派工数量
          total_time:this.report_baseData.dispatchNum,//合计用时
          qualified_num: this.report_contData.qualified_num, //合格数量
          residue_num: this.report_baseData.residueNum, //剩余数量
          scrap_num: this.report_contData.scrap_num, //报废数量
          total_time: this.report_contData.total_time, //合计用时
          start_time: this.report_contData.start_time, //开始时间
          ent_time: this.report_contData.end_time, //结束时间
          remark: this.report_contData.remark, //备注
        }
        //操作日志
        let log="[原报工验收信息为:派工数量为:"+this.oldreportBaseData.workNum+",加工人员:"+this.oldreportContData.operated_user_name
        +",合格数量:"+this.oldreportContData.qualified_num+",报废数量:"+this.oldreportContData.scrap_num+",原因:"
        +oldregectName.join(",")+",数量:"+oldregectNumber.join(",")+",开始时间:"+this.oldreportContData.start_time
        +",结束时间:"+this.oldreportContData.ent_time+",合计用时:"+this.oldreportContData.total_time+",备注:"+this.oldreportContData.remark
        +"]"+"[报工返工信息为:派工数量为:"+this.report_baseData.workNum+",加工人员:"+this.report_contData.operated_user
        +",合格数量:"+this.report_contData.qualified_num+",报废数量:"+this.report_contData.scrap_num+",返工原因:"
        +regectName.join(",")+",返工数量:"+regectNumber.join(",")+",开始时间:"+this.report_contData.start_time
        +",结束时间:"+this.report_contData.end_time+",合计用时:"+this.report_contData.total_time+",备注:"
        +this.report_contData.remark+"]进行返工操作"
        dataStute.logOper=log
        let totalNum=0
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            totalNum=totalNum+Number(item.number)
          }
        })
        this.$confirm("本次报工合格数为:"+this.report_contData.qualified_num+ ";返工数为:"+totalNum+"；报废数为:"+this.report_contData.scrap_num+"；合计用时:"+this.report_contData.total_time+"您确认要对该条数据进行返工吗?返工后状态不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '处理返工状态数据中,请稍后...');
          //获取需要修改的数据id
          data.id = this.reportDetailData.id;
          // 发送请求
          api.updateMesMainProcessRecord(data).then(res => {
            if (res.code == 200) {
              api.updateRework(dataStute).then(res=>{
                // 关闭loading框
                this.loading.close();
                //判断返回结果
                if (res.code == 200) {
                  this.cancleReport();
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data,
                    duration: this.elDuration
                  })
                }
              })
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
            }
          })


        }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消返工',
            duration: this.elDuration
          });
        })
      },
      /* 提交工序汇报信息type(0:提交汇报  1:汇报并完成) */
      commitReport(type) {
        let regectName=[] //报废名称
        let regectNumber=[] //报废数量
        let oldregectName=[] //原报废名称
        let oldregectNumber=[] //原报废数量
        this.processRegects.forEach((item,index)=>{
          if(!!item.number){
            regectName.push(item.name)
            regectNumber.push(item.number)
          }
        })
        this.oldyanshouProcessRegects.forEach((item,index)=>{
          if(!!item.number){
            oldregectName.push(item.name)
            oldregectNumber.push(item.number)
          }
        })
        //定义传入后端的数据
        let data = {
          mes_main_id: this.mesDetailData.id, //生产单id
          process_id: this.reportProcess.id, //工序id
          process_name:this.reportProcess.name,//工序名称
          check_user: this.report_contData.check_userId, //检验人员
          check_user_name: this.report_contData.check_user, //检验人员
          operated_user: this.report_contData.operated_userId, //加工人员
          operated_user_name: this.report_contData.operated_user, //加工人员
          work_num:this.report_baseData.workNum,//派工数量
          total_time:this.report_baseData.dispatchNum,//合计用时
          qualified_num: this.report_contData.qualified_num, //合格数量
          residue_num: this.report_baseData.residueNum, //剩余数量
          scrap_num: this.report_contData.scrap_num, //报废数量
          total_time: this.report_contData.total_time, //合计用时
          start_time: this.report_contData.start_time, //开始时间
          ent_time: this.report_contData.end_time, //结束时间
          remark: this.report_contData.remark, //备注
          mesProcessRejects:JSON.stringify(this.processRegects),//不良品项相关报废数
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#createReportBox", 4, '汇报信息创建中,请稍候...');
        //判断是新增工序还是修改工序
        if (this.operateType == 0) { //新增
let log="汇报记录为派工数量:"+this.report_baseData.workNum+",合格数量:"+this.report_contData.qualified_num
        +",报废数量:"+(!!this.report_contData.scrap_num?(this.report_contData.scrap_num+",原因:"+regectName.join(",")
        +","+regectNumber.join(",")):0)+",开始时间:"+this.report_contData.start_time+",结束时间:"+this.report_contData.end_time
        +",合计用时:"+this.report_contData.total_time+",加工人员为"+this.report_contData.operated_user  //日志记录
        data.logOper=log

          // 发送请求
          api.addMesMainProcessRecord(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.message,
                duration: this.elDuration
              })
              //关闭新增弹框
              this.cancleReport();
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.operateType == 1) { //修改
        //操作日志
        let log="[原报工验收信息为:派工数量为:"+this.oldreportBaseData.workNum+",加工人员:"+this.oldreportContData.operated_user_name
        +",合格数量:"+this.oldreportContData.qualified_num+",报废数量:"+this.oldreportContData.scrap_num+",原因:"
        +oldregectName.join(",")+",数量:"+oldregectNumber.join(",")+",开始时间:"+this.oldreportContData.start_time
        +",结束时间:"+this.oldreportContData.ent_time+",合计用时:"+this.oldreportContData.total_time+",备注:"+this.oldreportContData.remark
        +"]"+"[修改后验收信息为:派工数量为:"+this.report_baseData.workNum+",加工人员:"+this.report_contData.operated_user
        +",合格数量:"+this.report_contData.qualified_num+",报废数量:"+this.report_contData.scrap_num+",原因:"
        +regectName.join(",")+",数量:"+regectNumber.join(",")+",开始时间:"+this.report_contData.start_time
        +",结束时间:"+this.report_contData.end_time+",合计用时:"+this.report_contData.total_time+",备注:"
        +this.report_contData.remark+"]进行要修改操作"
          //获取需要修改的数据id
          data.id = this.reportDetailData.id;
          data.logOper=log
          // 发送请求
          api.updateMesMainProcessRecord(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功",
                duration: this.elDuration
              })
              //关闭新增弹框
              this.cancleReport();
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },
    },
    components: {
      departmentTree,
      personnelList,
    },
  }
</script>

<style>
.el-scrollbar__wrap {
    overflow-x: hidden!important;
}
</style>
