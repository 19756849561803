<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：出库物料
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billing_dialog_formtable">
    <!-- 表单 -->
    <div class="form">
      <ul class="form_ul">
        <li>
          <div class="form_title">出库类型:</div>
          <div class="form_date">
            <input type="text" placeholder="选择...">
          </div>
        </li>
        <li>
          <div class="form_title">出库仓:</div>
          <div class="form_date" @click="show_warehouseBox">
            <input type="text" placeholder="选择...">
            <span>
              <i class="el-icon-more"></i>
            </span>
          </div>
        </li>
        <li>
          <div class="form_title">往来单位:</div>
          <div class="form_date" @click="show_wldw=!show_wldw">
            <input type="text" placeholder="选择...">
            <span>
              <i class="el-icon-arrow-down"></i>
            </span>
            <!-- 下拉菜单 -->
            <ul class="pulldown_menu" v-show="show_wldw">
              <li>内部部门</li>
              <li>委外供应商</li>
            </ul>
          </div>
        </li>
        <li>
          <div class="form_title">出库日期:</div>
          <div class="form_date">
            <el-date-picker v-model="form_data.ckrq" type="date" placeholder="选择日期"></el-date-picker>
            <span><i class="el-icon-date"></i></span>
          </div>
        </li>
        <li>
          <div class="form_title">送货方式:</div>
          <div class="form_date" @click="show_shfs=!show_shfs">
            <input type="text" placeholder="选择...">
            <span>
              <i class="el-icon-arrow-down"></i>
            </span>
            <!-- 下拉菜单 -->
            <ul class="pulldown_menu" v-show="show_shfs">
              <li>自提</li>
              <li>送货</li>
            </ul>
          </div>
        </li>
        <li>
          <div class="form_title">归属项目:</div>
          <div class="form_date">
            <input type="text" placeholder="选择...">
          </div>
        </li>
      </ul>
    </div>

    <!-- 表格 -->
    <div class="list">
      <!-- 表格 -->
      <div class="list_table billing_table">
        <el-table :data="tableData" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="50"></el-table-column>
          <!-- 全选 -->
          <el-table-column type="selection" fixed width="45"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="company" label="货品编号" sortable width="180"></el-table-column>
          <el-table-column prop="customerNumber" label="货品名称" width="120"></el-table-column>
          <el-table-column prop="country" label="产品图片" width="80"></el-table-column>
          <el-table-column prop="star" label="规格型号" width="80"></el-table-column>
          <el-table-column prop="putTime" label="颜色" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="仓位" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="单位" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="出库数" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="批号" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="生产日期" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="失效日期" width="120"></el-table-column>
          <el-table-column prop="lastTime" label="备注" width="120"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="list_page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>

    <!-- 仓库选择组件 -->
    <listWarehouse :show_warehouse="show_warehouse"></listWarehouse>
  </div>
</template>

<script>
  // 导入组件
  import listWarehouse from '@/components/commComponent/list/list_warehouse.vue'

  export default{
    name:'outInventory',
    data(){
      return{
        // 控制开关
        show_shfs:false,
        show_wldw:false,

        show_warehouse:false,//控制显示仓库

        form_data:{
          cklx:'',
          ckc:'',
          wldw:'',
          ckrq:'',
          shfs:'',
          gsxm:''
        },

        tableData:[
          {
            company:'易外销',
            customerNumber:'20201212',
            country:'中国',
            star:'5',
            putTime:'2020-02-01',
            lastTime:'2020-02-01'
          }
        ]
      }
    },
    methods:{
      // 仓库
      show_warehouseBox(){
        console.log(1);
        this.show_warehouse=true;
      },
      hide_warehouseBox(){
        this.show_warehouse=false;
      },
    },
    components:{
      listWarehouse
    }
  }
</script>
