<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：汇报详情组件
	开始时间：2021-05-17
	开发人员：刘巍骏
	最后修改：2021-05-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="汇报详情" top="10vh" :visible.sync="show_reportDetail"
      :before-close="cancel_report" width="55%" append-to-body>
      <!-- 列表总框 -->
      <div class="billing_dialog_table" id="reportDetailBox">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" @click="getAllProcessDetailData">
            <i class="el-icon-menu"></i>
            <span>全部工序</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <ul v-for="elem in processData">
              <li @click="getProcessDetailByProcessId(elem)"> <span>{{elem.name}}</span></li>
            </ul>
          </div>
        </div>
        <!-- 可拉伸框 -->
        <div class="list_handel"></div>
        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="queryData.searchValue" placeholder="请输入查询内容">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list scollStyle">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table v-bind:data="reportDetail" height="100%" border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" fixed width="100">
                  <template slot-scope="scope">
                    <i class="el-icon-edit-outline" @click="updateReportDetail(scope.row)" title="修改"></i>
                    <i class="el-icon-delete icon" @click="deleteReportDetail(scope.row)" title="删除"></i>
                  </template>
                </el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="operated_user_name" label="加工人员" sortable width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="operated_user_name" label="检验人员" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="start_time"  label="汇报时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column prop="qualified_num" label="合格数" width="120"></el-table-column>
                <el-table-column prop="scrap_num" label="报废数" width="120"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80" show-overflow-tooltip></el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="getReportPageSize" @current-change="getReportCurrentPage"
                :current-page="queryData.currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="queryData.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="queryData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="cancel_report">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 新增工序汇报组件 -->
    <reportCreate :show_report="show_report" :reportProcess="reportProcess" :operateType="operateType" :reportDetailData="reportDetailData"
      :mesDetailData="mesDetailData"></reportCreate>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口

  //导入组件
  import reportCreate from './report_create.vue'; //新增工序汇报组件
  import personnelList from "@/components/commComponent/list/list_personnel.vue"; //负责人组件

  export default {
    name: 'list_reportDetail',
    props: {
      //控制显示汇报详情弹框
      show_reportDetail: {
        type: Boolean,
        default: false
      },
      //汇报详情
      processData: {
        type: Array,
        default () {
          return []
        }
      },
      //生产详情数据
      mesDetailData: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        // 当前组件数据
        reportDetail: [],

        //弹框请求数据
        queryData: {
          processId: -1, //工序id
          searchValue: '', //搜索框查询条件
          currentPage: 1, //当前页
          pageSize: 10, //页面大小
          total: 0, //总条数
        },

        // 工序汇报信息
        operateType: 0, //工序汇报操作方式(0:新增  1:修改)
        reportProcess: {}, //当前汇报工序
        reportDetailData:{},//工序汇报详情信息
        show_report: false, //控制显示新增汇报弹框

        //loading框
        loading:'',//loading框
      }
    },
    watch: {
      //监听汇报详情弹框显示
      show_reportDetail(newVal) {
        if (newVal == true) {
          // 获取汇报详情
          this.getProcessDetailData();
        }
      },
    },
    computed: {
      ...mapState({}),
    },
    created() {

    },
    mounted() {},
    methods: {
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制员工弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
      ]),

      /* 获取工序汇报详情列表*/
      getProcessDetailData() {
        //定义公共参数
        let data = {
          pageIndex: this.queryData.currentPage, //当前页
          pageSize: this.queryData.pageSize ,//页面大小
          mesMainId: this.mesDetailData.id, //生产单id
          deleteFlag:0,//未删除的
        }

        //判断是否有工序条件
        if (this.queryData.processId != -1) {
          data.mesProcessId = this.queryData.processId;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#reportDetailBox", 4, '汇报信息获取中,请稍候...');

        //发送请求
        api.findMesProcessListByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取数据
            this.reportDetail = res.data.rows;
            //获取总页数
            this.queryData.total = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取所有工序汇报列表 */
      getAllProcessDetailData(){
        //获取分类信息
        this.queryData.processId = -1;
        //获取数据
        this.getProcessDetailData();
      },

      /* 根据工序id查询汇报列表信息 */
      getProcessDetailByProcessId(data) {
        //获取分类信息
        this.queryData.processId = data.id;
        //获取数据
        this.getProcessDetailData();
      },

      /* 显示修改工序汇报弹框 */
      updateReportDetail(data){
        //获取需要修改的信息
        this.reportDetailData = data;
        //设置操作类型为修改
        this.operateType = 1;
        //显示天哪狂
        this.show_report = true;
      },

      /* 关闭新增工序汇报弹框 */
      closeReportCreateBox() {
        //关闭弹框
        this.show_report = false;
        //获取新数据
        this.getProcessDetailData();
      },

      //根据id删除汇报详情
      deleteReportDetail(data) {
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#reportDetailBox", 3, '数据删除中,请稍候...');
          //发送请求
          api.modifyProcessRecordDisable({
            id: data.id
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              //获取新的列表数据
              this.getProcessDetailData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 获取页面大小*/
      getReportPageSize(value) {
        this.queryData.pageSize = value;
      },

      /* 获取当前页*/
      getReportCurrentPage(value) {
        this.queryData.currentPage = value;
      },

      //确定修改汇报详情
      sureEdit(id) {
        console.log(this.processData);
        //定义传入后端的数据
        let data = {
          mes_main_id: this.mesDetailData.id, //生产单id
          process_id: this.processData[0].id, //工序id
          check_user: this.report_contData.check_userId, //检验人员
          operated_user: this.report_contData.operated_userId, //加工人员
          qualified_num: this.report_contData.qualified_num, //合格数量
          residue_num: this.report_baseData.residueNum, //剩余数量
          scrap_num: this.report_contData.scrap_num, //报废数量
          total_time: this.report_contData.total_time, //合计用时
          start_time: this.report_contData
            .start_time /* this.commonJsExtend.getDateTime(this.report_contData.start_time, 2) */ , //开始时间
          ent_time: this.report_contData
            .ent_time /* this.commonJsExtend.getDateTime(this.report_contData.ent_time, 2) */ , //结束时间
          remark: this.report_contData.remark, //备注
          id: this.editId
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#reportDetailBox", 2, '汇报信息修改中,请稍候...');
        api.updateMesMainProcessRecord(data).then(res => {
          console.log();
          if (res.code == 200) {
            //关闭加载框
            this.loading.close();
            if (this.leftId == null || this.leftId == '') { //在默认加载页面
              this.getReportData(0); //刷新页面
            } else {
              this.getReportData(); //刷新页面
            }
            this.$message({
              type: 'success',
              message: "修改成功!",
              duration: this.elDuration
            });
          } else {
            this.$message({
              type: 'error',
              message: "修改失败!",
              duration: this.elDuration
            });
          }
          this.editShow = false; //关闭弹窗

        })
      },

      // 关闭汇报详情页
      cancel_report() {
        this.$parent.closeDetailsBox(); //调用父组件方法关闭弹窗
      },
    },
    components: {
      personnelList,
      reportCreate
    }
  }
</script>

<style>
</style>
