<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产领料组件
	开始时间：2021-05-17
	开发人员：刘巍骏
	最后修改：2021-05-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="">
    <el-dialog :title="title" :visible.sync="materialGetBox" top="12vh" :before-close="cancleGetBox" width="70%"
      append-to-body>
      <div class="magerialGetBox billingcreate" id="mesMaterialGetBox">
        <!-- 基本信息框 -->
        <div class="billingcreate_form">
          <ul class="form_ul">
            <li v-if="[0,1,3,4].includes(getMaterOperateType)">
              <div class="form_title">
                单据编号:
              </div>
              <div class="form_date">
                <input v-model="getMaterialFormData.getMaterialNum" disabled type="text">
              </div>
            </li>
            <li>
              <div class="form_title">
                生产单号:
              </div>
              <div class="form_date">
                <input v-model="getMaterialFormData.productionNo" disabled type="text">
              </div>
            </li>
            <li v-if="[0,1,3,4].includes(getMaterOperateType)">
              <div class="form_title">
                <span v-if="getMaterOperateType == 0">领料仓库:</span>
                <span v-if="getMaterOperateType == 1">退料仓库:</span>
                <span v-if="getMaterOperateType == 3">采购仓库:</span>
                <span v-if="getMaterOperateType == 4">补料仓库:</span>
              </div>
              <div class="form_date" @click="SHOW_WAREHOUSEBOX(true)">
                <input v-model="getMaterialFormData.warehouseName" type="text" placeholder="请选择仓库">
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                <span v-if="getMaterOperateType == 0">领料人:</span>
                <span v-if="getMaterOperateType == 2">登记人:</span>
                <span v-if="getMaterOperateType == 1">退料人:</span>
                <span v-if="getMaterOperateType == 3">申请人:</span>
                <span v-if="getMaterOperateType == 4">补料人:</span>
              </div>
              <div class="form_date" @click="SHOW_PERSONNELBOX(true)">
                <input v-model="getMaterialFormData.userName" type="text" placeholder="请选择领料人">
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li v-if="[1,3,4].includes(getMaterOperateType)">
              <div class="form_title">
                <span v-if="getMaterOperateType == 1">退料原因:</span>
                <span v-if="getMaterOperateType == 3">采购原因:</span>
                <span v-if="getMaterOperateType == 4">补料原因:</span>
              </div>
              <div class="form_date" @click="SHOW_PERSONNELBOX(true)">
                <input v-model="getMaterialFormData.reason" type="text" placeholder="请填写原因">
              </div>
            </li>
          </ul>
        </div>

        <!-- 物料内容框 -->
        <div class="getMaterialTable billing_table">
          <el-table :data="getMaterialData" :indent="18" row-key="product_info_id" height="100%"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" border style="width: 100%"
            @select="getSelectedData" @select-all="getSelectedAllData" :default-expand-all="true">
            <!-- 全选 -->
            <el-table-column type="selection" fixed width="45"></el-table-column>
            <!-- 内容 -->
            <el-table-column prop="product_info_code" label="编号" sortable width="180"></el-table-column>
            <el-table-column prop="product_info_name" label="物料名称" width="160"></el-table-column>
            <el-table-column prop="specifications" label="规格" width="180"></el-table-column>
            <el-table-column prop="need_total_num" label="所需总数量" v-if="[0,1,3,4].includes(getMaterOperateType)"
              width="80"></el-table-column>
            <el-table-column prop="need_all_num" label="所需总数量" v-if="[2].includes(getMaterOperateType)" width="80">
            </el-table-column>
            <el-table-column prop="get_num" v-if="getMaterOperateType == 0" label="已领料数" width="80"></el-table-column>
            <el-table-column prop="already_return_num" v-if="getMaterOperateType == 1" label="已退料数" width="80">
            </el-table-column>
            <el-table-column prop="already_req_num" v-if="getMaterOperateType == 3" label="已采购数" width="80">
            </el-table-column>
            <el-table-column prop="get_num" v-if="getMaterOperateType == 4" label="已领料数" width="80"></el-table-column>
            <!-- 领料相关 -->
            <el-table-column prop="current_num" label="本次领料" width="100" v-if="getMaterOperateType == 0">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次领料</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="领料数" v-model="scope.row.current_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <!-- 退料相关 -->
            <el-table-column prop="current_num" label="本次退料" width="100" v-if="getMaterOperateType == 1">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次退料</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="退料数" v-model="scope.row.current_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <!-- 补料相关 -->
            <el-table-column prop="current_num" label="本次补料" width="100" v-if="getMaterOperateType == 4">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次补料</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="补料数" v-model="scope.row.current_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <!-- 采购相关 -->
            <el-table-column prop="current_num" label="本次采购" width="100" v-if="getMaterOperateType == 3">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次采购</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="采购数" v-model="scope.row.current_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <!-- 登记相关 -->
            <el-table-column prop="already_num" v-if="getMaterOperateType == 2" label="已用数" width="80"></el-table-column>
            <el-table-column prop="scrap_num" v-if="getMaterOperateType == 2" label="已废数" width="80"></el-table-column>
            <el-table-column prop="current_use_num" label="本次用料" width="100" v-if="getMaterOperateType == 2">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次用料</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="用料数" v-model="scope.row.current_use_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <el-table-column prop="current_discard_num" label="本次废料" width="100" v-if="getMaterOperateType == 2">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次废料</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="废料数" v-model="scope.row.current_discard_num"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <!-- 计量单位 -->
            <el-table-column prop="calculateName" label="计量单位" min-width="60"></el-table-column>
            <!-- 库存数据 -->
            <!-- <el-table-column prop="inventory_num" label="可用库存" width="80"></el-table-column> -->
          </el-table>
        </div>
      </div>

      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commitManageMaterial(0)">提交</el-button>
        <!-- <el-button type="primary" size="mini" @click="commitManageMaterial(1)">提交并审核</el-button> -->
        <el-button size="mini" @click="cancleGetBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 仓库选择组件 -->
    <warehouseList @SelectedData="getWarehouseData"></warehouseList>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData"></personnelList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import nodeFormBase from '@/components/commComponent/billing/nodeFormItem/nodeForm_base.vue'; //基本信息组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件

  // 导入方法
  import api from '@/network/stock/stock.js'; //出入库单相关接口
  import mesApi from '@/network/production/production.js'; //生产相关接口
  import stockApi from '@/network/stock/stock.js'; //生产生成相关出入库单相关接口
  import purchaseApi from '@/network/stock/purchase.js'; //生产生成相关采购申请单接口
  import billsCommApi from '@/network/billsComm/billsComm.js'; //单据公共接口

  export default {
    name: 'materialGet',
    props: {
      //控制显示生产领料弹框
      materialGetBox: {
        type: Boolean,
        default: false
      },
      //物料操作类型(0:领料 1:退料  2:登记  3:采购  4:补料)
      getMaterOperateType: {
        type: Number,
        default: -1
      },
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      },
      //生产领料物料数据
      materialManageData: {
        type: Array,
        default: []
      },
      //当前登记工序id
      processId: {
        type: Number,
        default: 0
      },
      //当前登记工序名称
      processName: {
        type: String,
        default: ''
      },
    },
    data() {
      return {

        title: '', //标题
        getMaterialData: [], //生产领料数据
        materialIdStr: "", //物料id字符串
        //当前组件数据
        getMaterialFormData: { //生产领料表单数据
          getMaterialNum: '', //领料单单据编号
          productionId: '', //生产单id
          productionNo: '', //生产单编号
          warehouseId: '', //仓库id
          warehouseName: '', //仓库名称
          userName: '', //领料/退料/补料人名称
          userId: '', //领料/退料/补料人id
          reason: '', //退料/补料/采购原因
        },
        selMaterialData: [], //已选物料数据

        // loading框
        loading: '',

        inventory_num: '', //可用库存
        //字符串物料id
        idStr: "",
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
      })
    },
    created() {},
    mounted() {
      // 初始化vuex数据字典数据
      this.initVuexData();

      //获取title名称
      this.getTitleName();
    },
    watch: {},
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
      ]),
      ...mapActions([
        'getWarehouseConfig', //获取仓库配置
      ]),

      /* 初始化界面数据字典数据 */
      async initVuexData() {
        //仓库数据
        if (this.WarehouseConfig.length == 0) {
          await this.getWarehouseConfig();
        }
        // 初始化领料数据
        this.initGetMaterialFormData();
        //初始化物料表格数据
        this.initGetMaterialTableData();
      },

      /*获得标题名称*/
      getTitleName() {
        if (this.getMaterOperateType == 0) {
          this.title = "生产领料"
        } else if (this.getMaterOperateType == 1) {
          this.title = "生产退料"
        } else if (this.getMaterOperateType == 2) {
          this.title = "用料登记"
        }else if (this.getMaterOperateType == 3) {
          this.title = "采购申请单"
        } else if (this.getMaterOperateType == 4) {
          this.title = "生产补料"
        }
      },

      /* 初始化领料数据 */
      initGetMaterialFormData() {
        //判断操作方式(用料登记不需要获取编码)
        if ([0, 1, 3, 4].includes(this.getMaterOperateType)) {
          //获取生产领料单单据编号
          this.getBillsNumber();
        }
        //获取单据表单信息
        this.getMaterialFormData.productionId = this.mesDetailData.id; //生产单id
        this.getMaterialFormData.productionNo = this.mesDetailData.bills_no; //生产单编号
        //获取默认领料/退料/补料人
        this.getMaterialFormData.userId = this.UserInfo.userId; //用户id
        this.getMaterialFormData.userName = this.UserInfo.user_name; //用户名称
        //获取默认仓库
        this.WarehouseConfig.forEach((item, index) => {
          if (item.is_main == 0) {
            this.getMaterialFormData.warehouseId = item.id; //仓库id
            this.getMaterialFormData.warehouseName = item.name; //仓库名称
          }
        })
      },

      /* 初始化物料表格内容数据 */
      initGetMaterialTableData() {
        //获取表格数据
        this.getMaterialData = JSON.parse(JSON.stringify(this.materialManageData));
        //获取非树形结构数据
        let noTreeData = this.commonJsExtend.toNoTree(this.getMaterialData, [])
        //循环获取当前领料数据字段
        noTreeData.forEach((item, index) => {
          //判断操作类型
          if ([0, 1, 3, 4].includes(this.getMaterOperateType)) { //领料/退料/申请/补料用同一个参数
            this.$set(item, 'current_num', 0);
          } else if (this.getMaterOperateType == 2) { //用料登记
            this.$set(item, 'current_use_num', 0); //本次用料
            this.$set(item, 'current_discard_num', 0); //本次废料
          }
        })
        //转换为树形结构
        this.getMaterialData = this.commonJsExtend.toTree(noTreeData, 'mes_bom_parent_id');

        //判断登记时转换为非驼峰结构
        if (this.getMaterOperateType == 2) {
          this.getMaterialData.forEach((item, index) => {
            for (let i in item) {
              //转换非驼峰
              let itemKey = this.commonJsExtend.toLine(i);
              //重新赋值
              item[itemKey] = item[i];
            }
          })
        }
        //获取库存信息
        // console.log(this.getMaterialData)
      },

      /* 获取单据编号*/
      getBillsNumber(data, billsType) {
        //定义后端接受参数
        let typeData = {}
        //判断操作方式
        if (this.getMaterOperateType == 0) { //领料
          typeData.templateCode = 'DOC-M-014'; //默认获取 生产领料出库单 单据类型
          typeData.sign = 2; //库存模块
        } else if (this.getMaterOperateType == 1) { //退料
          typeData.templateCode = 'DOC-M-011'; //默认获取  生产退料入库单 单据类型
          typeData.sign = 2; //库存模块
        } else if (this.getMaterOperateType == 3) { //采购
          typeData.templateCode = 'DOC-P-005'; //默认获取 生产采购单 单据类型
          typeData.sign = 3; //采购模块
        } else if (this.getMaterOperateType == 4) { //补料
          typeData.templateCode = 'DOC-M-017'; //默认获取 生产补料出库单 单据类型
          typeData.sign = 2; //库存模块
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据编码生成中,请稍候...');
        //发送请求
        billsCommApi.findBillsCodeByTempBillsCode(typeData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取单据类型id
            this.getMaterialFormData.getMaterialNum = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 提交生产物料数据 */
      commitManageMaterial(type) {
        //判断是否已经选择需要领取的物料
        if (this.selMaterialData.length == 0) {
          this.$message({
            type: 'warning',
            message: '请选择需要操作的物料!',
            duration: this.elDuration
          })
          return
        }
        //判断已选择的物料领取物料数是否大于0
        let materialCodeArr = []; //领料数量
        let few_materialCodeArr = []; //数量太少
        let more_materialCodeArr = []; //数量太多
        this.selMaterialData.forEach((item, index) => {
          //判断领料数量是否大于0
          if (item.current_num <= 0) {
            materialCodeArr.push(item.product_info_code);
          }
          //判断数量是否大于未领取数量(未添加)
        })
        //判断是否有不合法的物料
        if (materialCodeArr.length > 0) {
          this.$message({
            type: 'warning',
            message: "物料编号为【" + materialCodeArr.join(",") + "】的数量需大于0,请确认!",
            duration: this.elDuration
          })
          return
        }


        //获取需要生成领料单的物料
        let materialIdList = []; //物料id数组
        let materialNumList = []; //对应领料数数组

        this.selMaterialData.forEach((item, index) => {
          //获取id数组
          materialIdList.push(item.id);
          //判断当前界面类型
          //获取数量数组
          materialNumList.push(item.current_num);
        })

        //当为生产物料的时候
        if (this.getMaterOperateType == 0) {
          //定义传入后端的数据
          let data = {
            mesMainId: this.mesDetailData.id, //生产单id
            houseId: this.getMaterialFormData.warehouseId, //所选仓库id
            materialIdList: materialIdList.join(','), //所选物料数据id
            materialNumList: materialNumList.join(',') //所选物料对应数量
          }

          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
          //发送请求
          mesApi.generateDepotBillsByMesMaterial(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.cancleGetBox()
              this.$confirm('生产领料单已生成是否跳转到生产领料单界面', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path: "/warehouse_view?inventoryType=1&inventoryBillCode=DOC-M-014",
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消跳转'
                });
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.getMaterOperateType == 1) { //当为退料单的时候
          //定义传入后端的数据
          let data = {
            // mesMainId:this.mesDetailData.id,//生产单id
            houseId: this.getMaterialFormData.warehouseId, //所选仓库id
            ids: materialIdList.join(','), //所选物料数据id
            numbers: materialNumList.join(',') //所选物料对应数量
          }

          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
          //发送请求
          stockApi.productionMaterialReturn(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.cancleGetBox()
              this.$confirm('生产退料单已生成是否跳转到生产退料单界面', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path: "/warehouse_view?inventoryType=0&inventoryBillCode=DOC-M-011",
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消跳转'
                });
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
        else if(this.getMaterOperateType == 2){//当为用料登记的时候
        let currentDiscardNum=[];//本次废料
        let currentUseNum=[];//本次用料
        let productName=[];//本次选择物料名称
        let dissatisfy=[];//选了却什么都没填数组判断
        let productIds=[];//物料id

        this.selMaterialData.forEach((item,indexl)=>{
         productName.push(item.product_info_code)
          productIds.push(item.product_info_id)
          if(!!item.current_discard_num && !!item.current_use_num){
            currentDiscardNum.push(item.current_discard_num)
            currentUseNum.push(item.current_use_num)
          }else{
            if(!!!item.current_discard_num && !!!item.current_use_num){
              currentUseNum.push("0")
              currentDiscardNum.push("0")
              dissatisfy.push("0")
            }
            else if(!!!item.current_use_num){
              currentUseNum.push("0")
              currentDiscardNum.push(item.current_discard_num)
            }
            else if(!!!item.current_discard_num){
              currentUseNum.push(item.current_use_num)
              currentDiscardNum.push("0")
            }
          }

        })
        if(dissatisfy.length>0){
          this.$message({
            type: 'warning',
            message: "请填写选中的用料或废料信息其中一个",
            duration: this.elDuration
          })
          return
        }

          let data={
            processId:this.processId,//工序id
            mesMainId: this.mesDetailData.id, //生产单id
            currentDiscardNum:currentDiscardNum.join(","),//所选物料本次废料数组
            currentUseNum:currentUseNum.join(","),//所选物料本次使用料
            ids: productIds.join(','), //所选物料数据id
            processMaterialId:materialIdList.join(','),//所选工序物料id
          }
          let log="对工序:"+this.processName+",进行物料登记"+"登记内容为:登记物料编号为"+productName.join(",")
          +",本次用料为"+currentUseNum.join(",")+",本次废料为:"+currentDiscardNum.join(",")
          data.logOperation=log
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '用料登记添加中,请稍候...');
          mesApi.addParticularMesMaterialRecord(data).then(res=>{
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.cancleGetBox()
              this.$parent.getProcessData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })

        }
         else if (this.getMaterOperateType == 4) { //当为生产补料单
          //定义传入后端的数据
          let data = {
            // mesMainId:this.mesDetailData.id,//生产单id
            houseId: this.getMaterialFormData.warehouseId, //所选仓库id
            ids: materialIdList.join(','), //所选物料数据id
            numbers: materialNumList.join(',') //所选物料对应数量
          }

          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
          //发送请求
          stockApi.ProductionReplenishment(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.cancleGetBox()
              this.$confirm('生产补料单已生成是否跳转到生产补料单界面', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path: "/warehouse_view?inventoryType=1&inventoryBillCode=DOC-D-017",
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消跳转'
                });
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.getMaterOperateType == 3) { //当为生成申请单
          //定义传入后端的数据
          let data = {
            // mesMainId:this.mesDetailData.id,//生产单id
            houseId: this.getMaterialFormData.warehouseId, //所选仓库id
            ids: materialIdList.join(','), //所选物料数据id
            numbers: materialNumList.join(',') //所选物料对应数量
          }

          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
          //发送请求
          purchaseApi.generatePurchaseRequisition(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.cancleGetBox()
              this.$confirm('采购申请单已生成是否跳转到采购申请单界面', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path: "/purchase_view?purchaseType=0&purchaseBillCode=DOC-P-001",
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消跳转'
                });
              });
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })

        }

      },

      /* 获取所选仓库数据 */
      getWarehouseData(data) {
        this.getMaterialFormData.warehouseId = data.id; //获取仓库id
        this.getMaterialFormData.warehouseName = data.name; //获取仓库名称
      },

      /* 获取领料人弹框数据 */
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        this.getMaterialFormData.userId = result.idStr; //id字符串
        this.getMaterialFormData.userName = result.nameShowStr; //名称字符串
      },

      /* 获取表格已选数据 */
      getSelectedData(selection, row) {
        // 将已选数据存入data
        this.selMaterialData = selection;
        console.log(this.selMaterialData);
      },

      /* 全选时触发 */
      getSelectedAllData(selection) {
        this.selMaterialData = selection;
      },

      /* 取消新增退料弹框 */
      cancleGetBox() {
        this.$parent.closeGetMaterialBox();
      },
    },
    components: {
      warehouseList,
      personnelList
    }
  }
</script>

<style lang="less">
  .magerialGetBox {
    height: 50vh;
  }

  .getMaterialTable {
    height: 80%;
  }
</style>
