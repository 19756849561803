<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.工艺方案
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="detail_itemtechnology" id="techExpend">
    <!-- 工艺方案头部按钮 -->
    <!-- <div class="detailtab_head">
      <el-button type="primary" size="small" @click="show_technologform=true">新增工艺方案</el-button>
    </div> -->
    <!-- 工艺方案表格 -->
    <div class="detailtab_table billing_table">
      <el-table :data="tableData" height="100%" border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <i class="el-icon-view operate_icon" @click="showFilePreView(scope.row)"></i>
          </template>
          <!-- <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template> -->
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="file_name" label="文件名" sortable width="250"></el-table-column>
        <el-table-column prop="remark" label="备注" width="180"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180"></el-table-column>
      </el-table>
    </div>

    <!-- 弹框 -->
    <!-- 新建工艺方案弹框 -->
    <el-dialog title="新增工艺方案" :visible.sync="show_technologform" width="30%">
      <!-- 产品列表组件 -->
      <technologyFileForm></technologyFileForm>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="show_technologform = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="show_technologform = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import technologyFileForm from '@/components/commComponent/form/technologyFileForm.vue'
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  // 导入接口请求
  import api from '@/network/production/production.js'

  export default{
    name:'detail_technology',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        // 测试用
        input:'',

        previewFilePath: '', //附件预览路径

        //控制开关
        show_technologform:false,

        //生产工艺列表
        tableData:[]
      }
    },
    watch:{
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "3") {
          // 获取生产工艺相关信息
          this.queryProcessInformation();
        }
      },
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },
    methods:{
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),
      queryProcessInformation(){
        let data={
          mesId:this.mesDetailData.id
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#techExpend", 4, '生产工艺信息获取中,请稍后...');
        api.queryProcessInformation(data).then(res=>{
          if(res.code==200){
            this.tableData=res.data
          }else{
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          this.loading.close()
        })
      },
      /* 显示附件预览弹框 */
      showFilePreView(data) {
      
        //获取预览路径
        this.previewFilePath = data.file_name + "@@@" + data.file_url;
        console.log(this.previewFilePath)
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

    },
    components:{
      technologyFileForm,
      filePreview
    }
  }
</script>

<!-- 自定义Element ui 样式 -->
<style lang="less">
  .detail_itemtechnology{
    .el-dialog__body{
      padding: 10px 10px;
      // border: 1px solid black;
    }
  }
</style>
