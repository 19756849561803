<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.生产工序
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="proDetailProcess">
    <!-- 生产工序头部按钮 -->
   <!-- <div class="detailtab_head">
      <el-button type="primary" size="small" @click="showProcessBox">新增工序</el-button>
    </div> -->
    <!-- 生产工序表格 -->
    <div class="detailtab_table billing_table">
      <el-table :data="processData" id="detailSort" row-key="id" height="100%" border show-summary style="width: 100%">
        <!-- 拖拽图标 -->
       <!-- <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column> -->

        <!-- 操作 -->
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-tooltip placement="right" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <!-- <li>
                    <i class="el-icon-edit-outline operate_icon" title="修改"></i>
                    <span class="left_padding_10">修改</span>
                  </li> -->
                  <li @click="showReportBox(scope.row)">
                    <i class="el-icon-s-promotion operate_icon" title="汇报"></i>
                    <span class="left_padding_10">汇报</span>
                  </li>
                  <li @click="showRegisterBox(scope.row)">
                    <i class="el-icon-s-promotion operate_icon" title="登记"></i>
                    <span class="left_padding_10">登记</span>
                  </li>
                  <!-- <li @click="show_reportDetail=true">
                    <i class="el-icon-warning-outline operate_icon" title="详情"></i>
                    <span class="left_padding_10">详情</span>
                  </li> -->
                  <li @click="completedProcess(scope.row)">
                    <i class="el-icon-circle-check operate_icon" title="完成"></i>
                    <span class="left_padding_10">完成</span>
                  </li>
                  <!-- <li>
                    <i class="el-icon-delete operate_icon" title="删除"></i>
                    <span class="left_padding_10">删除</span>
                  </li> -->
                </ul>
              </div>
              <i class="el-icon-more icon_color_red"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <!-- 工序数据 -->
        <el-table-column prop="name" sortable label="工序名称" width="120"></el-table-column>
        <el-table-column prop="deptName" label="负责部门" width="100"></el-table-column>
        <el-table-column prop="userName" label="负责人" width="150"></el-table-column>
        <!-- 汇报数据 -->
        <el-table-column label="工序状态" width="80">
          <template slot-scope="scope">
            <el-button v-if="scope.row.processState == 0" type="info" size="mini">未开始</el-button>
            <el-button v-if="scope.row.processState == 1 && new Date().getTime()>new Date(scope.row.startTime).getTime()" type="primary" size="mini">进行中</el-button>
            <el-button v-if="scope.row.processState == 1 && new Date().getTime()<new Date(scope.row.startTime).getTime()" type="info" size="mini">未开始</el-button>
            <el-button v-if="scope.row.processState == 2" type="danger" size="mini">质检中</el-button>
            <el-button v-if="scope.row.processState == 3" type="danger" size="mini">返工中</el-button>
            <el-button v-if="scope.row.processState == 4" type="success" size="mini">已完成</el-button>
          </template>
        </el-table-column>
        <el-table-column label="返工状态" width="80">
          <template slot-scope="scope">
            <el-button v-if="scope.row.reworkStatus == 0" type="success" size="mini">无返工</el-button>
            <el-button v-if="scope.row.reworkStatus == 1" type="danger" size="mini">有返工</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="workNum" label="派工数量" width="80"></el-table-column>
        <el-table-column prop="qualifiedNum" label="合格数量" width="80"></el-table-column>
        <el-table-column prop="residueNum" label="剩余数量" width="80"></el-table-column>
        <el-table-column prop="scrapNum" label="报废数量" width="80"></el-table-column>
        <el-table-column prop="totalTime" label="合计用时" width="80"></el-table-column>
        <el-table-column prop="costTypeName" label="计费方式" width="120"></el-table-column>
        <el-table-column prop="hours" label="参考用时" width="80"></el-table-column>
        <el-table-column prop="manufactureTypeName" label="生产类型" width="80"></el-table-column>
        <el-table-column prop="fileName" label="图纸" width="150">
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" title="点击查看"
              @click="seeImgUrl(scope.row)">{{scope.row.fileName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
      </el-table>
    </div>

    <!-- 汇报详情组件 -->
    <reportDetail :show_reportDetail="show_reportDetail" :processData="processData" :mesDetailData="mesDetailData">
    </reportDetail>

    <!-- 新增工序汇报组件 -->
    <reportCreate :show_report="show_report"  :processRegects="processRegects" :reportProcess="reportProcess" :operateType="operateType"
      :mesDetailData="mesDetailData"></reportCreate>

    <!-- 工序选择组件 -->
    <technologyList @SelectedData="getSelTechnologyData"></technologyList>

    <!-- 生产领料/退料/补料/请购公共弹框 -->
    <materialGet :materialGetBox="materialGetBox" :mesDetailData="mesDetailData" :getMaterOperateType="operateManageType"
      :materialManageData="materialManageData" :processId="processId" :processName="processName" v-if="materialGetBox"></materialGet>

      <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入组件
  import materialGet from './item_compontent/material_get.vue'; //生产领料弹框
  import technologyList from '@/components/commComponent/list/list_technology.vue'; //工序选择组件
  import reportDetail from './item_compontent/report_detail.vue'; //汇报详情组件
  import reportCreate from './item_compontent/report_create.vue'; //新增工序汇报组件
  import dicApi from '@/network/system/dictionary';//数据字典相关方法
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  // import
  //引入拖动排序
  import Sortable from "sortablejs";
  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口

  export default {
    name: 'detail_process',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        // 工序tab页信息
        processData: [], //工序列表

        //控制开关
        show_report: false, //控制显示新增汇报弹框
        show_reportDetail: false, //显示汇报详情弹框

        // 工序汇报信息
        operateType: 0, //工序汇报操作方式(0:新增  1:修改)
        reportProcess: {}, //当前汇报工序

        //数据字典 关于不良品项
        processRegects:[],
        //工序用料登记信息
        materialGetBox: false, //控制显示生产领料弹框
        operateManageType:2,//操作方式(登记)
        materialManageData:[],//需要登记的物料数据
        processId:'',//当前选择工序id
        processName:'',//当前选择工序名称

        //loading框
        loading: '', //loading框

        previewFilePath: '', //附件预览路径
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "2") {
          // 获取工序数据
          this.getProcessData();
        }
      },
    },
    mounted() {
      this.initDrag(); //初始化排序

    },

    methods: {
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制员工弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_TECHNOLOGYBOX', //控制工序选择弹框
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),


      /* 获取工序列表信息 */
      getProcessData() {
        //定义传入后端的数据
        let data = {
          mesMainId: this.mesDetailData.id, //生产单id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#proDetailProcess", 4, '工序信息更新中,请稍后...');

        // 发送请求
        api.findMesProcessListByMesMainId(data).then(res => {
          //关闭loadign框
          this.loading.close();
          // 判断返回结果
          if (res.code == 200) {
            if(!!res.data){
              //判断有无图纸
              res.data.forEach((item,index)=>{
              if(!!item.imgUrl){
                item.fileName=item.imgUrl.split("@@@")[1]
            }
              })
            }
            this.processData = res.data;
            
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      

      /* 显示新增工序汇报弹框 */
      showReportBox(data) {
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "计划单未审核，不可以使用该功能",
            duration: this.elDuration
          })
          return
        }
        if(data.processState==4){
          this.$message({
            type: 'warning',
            message: "该工序已完成，不可以汇报",
            duration: this.elDuration
          })
          return
        }
        //判断是否按流程 不为0则没按流程 抛出提示
        let flow=0
        //判断是否是按流程进行
        if(this.companyparamlist.param265==1){
           this.processData.forEach((item,index)=>{
             if(item.id==data.id && index!=0){
               if(this.processData[index].processState!=4){
                 flow=1
               }
             }
           })
           if(flow==1){
             this.$message({
               type: 'warning',
               message: "管理员设置按工序流程进行，请先完成上一个工序",
               duration: this.elDuration
             })
             return
           }
        }

        this.loading = this.commonJsExtend.customLoading("#proDetailProcess", 4, '汇报加载中,请稍后...');
        dicApi.production.findAllProcessRejects().then(res=>{
          if(res.code==200){
            this.processRegects=res.data
            //获取当前汇报的工序信息
            this.reportProcess = data;
            //显示工序汇报弹框
            this.show_report = true;
          }
          else{
            // 显示提示信息
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          this.loading.close()
        })

      },
      
      //预览图纸
      seeImgUrl(data){
          //获取预览路径
        this.previewFilePath = data.fileName + "@@@" + data.imgUrl.split('@@@')[0];
        console.log(this.previewFilePath)
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
       
      },

      /* 显示用料登记弹框 */
      showRegisterBox(data){
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "计划单未审核，不可以使用该功能",
            duration: this.elDuration
          })
          return
        }
        if(data.processState==4){
          this.$message({
            type: 'warning',
            message: "该工序已完成，不可以登记",
            duration: this.elDuration
          })
          return
        }
        //判断是否按流程 不为0则没按流程 抛出提示
        let flow=0
        //判断是否是按流程进行
        if(this.companyparamlist.param265==1){
           this.processData.forEach((item,index)=>{
             if(item.id==data.id && index!=0){
               if(this.processData[index].processState!=4){
                 flow=1
               }
             }
           })
           if(flow==1){
             this.$message({
               type: 'warning',
               message: "管理员设置按工序流程进行，请先完成上一个工序",
               duration: this.elDuration
             })
             return
           }
        }

        this.processId= data.id;
        this.processName=data.name;
        //获取数据
        this.materialManageData = data.assignMaterialList;
        //显示弹框
        this.materialGetBox = true;
      },

      /*完成工序*/
      completedProcess(data){
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "计划单未审核，不可以使用该功能",
            duration: this.elDuration
          })
          return
        }
        if(data.processState==4){
          this.$message({
            type: 'warning',
            message: "该工序已完成，请不要重复完成",
            duration: this.elDuration
          })
          return
        }
        //判断是否按流程 不为0则没按流程 抛出提示
        let flow=0
        //判断是否是按流程进行
        if(this.companyparamlist.param265==1){
           this.processData.forEach((item,index)=>{
             if(item.id==data.id && index!=0){
               if(this.processData[index].processState!=4){
                 flow=1
               }
             }
           })
           if(flow==1){
             this.$message({
               type: 'warning',
               message: "管理员设置按工序流程进行，请先完成上一个工序",
               duration: this.elDuration
             })
             return
           }
        }
        let processData={
          id:data.id
        }
        this.$confirm("当前派工数量:"+data.workNum +"合格数为:"+data.qualifiedNum+"，手工结束后将不能再次执行本工序的报工，工序生产数据以此为准了，确定要结束吗!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.loading= this.commonJsExtend.customLoading("#proDetailProcess", 4, '工序完成数据处理中,请稍后...');
          api.completedProcess(processData).then(res=>{
            if(res.code==200){
              this.$message({
                type: 'success',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close()
              this.getProcessData()
            }else{
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close()
            }

          })
        }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消完成操作',
            duration: this.elDuration
          });
        })

      },


      /* 选择的工序数据 */
      getSelTechnologyData() {},

      /* 显示工序选择弹框 */
      showProcessBox() {
        this.SHOW_TECHNOLOGYBOX(true);
      },

      /* 子组件关闭详情弹窗 */
      closeDetailsBox() {
        this.show_reportDetail = false;
      },


      /* 关闭新增工序汇报弹框 */
      closeReportCreateBox() {
        this.show_report = false;
      },
      /* 关闭登记用料弹框 */
      closeGetMaterialBox() {
        this.materialGetBox = false;
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        //初始化需要拖动的容器
        const el = document.querySelectorAll("#detailSort>.el-table__body-wrapper > table > tbody")[0];
        Sortable.create(el, {
          disabled: false, // 拖拽不可用? false启用
          // ghostClass: 'sortable-ghost', //拖拽样式
          // chosenClass: "chosen",
          // dragClass: "chosen",
          animation: 150, // 拖拽延时，效果更好看
          handle: ".my-handle",
          onEnd: (e) => { // 拖拽结束时的触发
            let data = {
              newIndex: e.newIndex, //改变后的下标
              oldIndex: e.oldIndex, //改变前的下标
              id: this.mesDetailData.id, //主键id
            }
            this.loading = this.commonJsExtend.customLoading("#detailSort", 1);
            api.updateProcessSort(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                this.getProcessData();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          },
        });
      },
    },
    components: {
      technologyList,
      materialGet,
      reportDetail,
      reportCreate,
      filePreview
    }
  }
</script>

<style lang="less">
  .reportDetailBox {
    // 汇报详情外框
    width: 100%;
    height: 50vh;
  }
</style>
