<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：申请采购物料
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billing_dialog_formtable">
    <!-- 表单 -->
    <div class="form">
      <ul class="form_ul">
        <li>
          <div class="form_title">请购时间:</div>
          <div class="form_date">
            <el-date-picker type="date" v-model="form_data.qgsj" placeholder="选择日期"/>
            <span><i class="el-icon-date"></i></span>
          </div>
        </li>
        <li>
          <div class="form_title">用料时间:</div>
          <div class="form_date">
            <el-date-picker type="date" v-model="form_data.ylsj" placeholder="选择日期"/>
            <span><i class="el-icon-date"></i></span>
          </div>
        </li>
        <li>
          <div class="form_title">原始订单号:</div>
          <div class="form_date">
            <input type="text" v-model="form_data.ysddh" placeholder="选择...">
          </div>
        </li>
        <li>
          <div class="form_title">原始生产单号:</div>
          <div class="form_date">
            <input type="text" v-model="form_data.ysscdh" placeholder="选择...">
          </div>
        </li>
        <li>
          <div class="form_title">是否有退料:</div>
          <div class="form_date" @click="show_sftl=!show_sftl">
            <input type="text" v-model="form_data.sftl" placeholder="选择...">
            <span @click="show_type=!show_type">
              <i class="el-icon-arrow-down"></i>
            </span>
            <!-- 下拉菜单 -->
            <ul class="pulldown_menu" v-show="show_sftl">
              <li>是</li>
              <li>否</li>
            </ul>
          </div>
        </li>
        <li>
          <div class="form_title">是否有报废:</div>
          <div class="form_date" @click="show_sfbf=!show_sfbf">
            <input type="text" v-model="form_data.sfbf" placeholder="选择...">
            <span @click="show_type=!show_type">
              <i class="el-icon-arrow-down"></i>
            </span>
            <!-- 下拉菜单 -->
            <ul class="pulldown_menu" v-show="show_sfbf">
              <li>是</li>
              <li>否</li>
            </ul>
          </div>
        </li>
        <li>
          <div class="form_title">退料单号:</div>
          <div class="form_date">
            <input type="text" v-model="form_data.tldh" placeholder="选择...">
          </div>
        </li>
        <li>
          <div class="form_title">申请事由:</div>
          <div class="form_date">
            <input type="text" v-model="form_data.sqsy" placeholder="选择...">
          </div>
        </li>
      </ul>
    </div>

    <!-- 表格 -->
    <div class="list">
      <!-- 表格 -->
      <div class="list_table billing_table">
        <el-table :data="tableData" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="50"></el-table-column>
          <!-- 全选 -->
          <el-table-column type="selection" fixed width="45"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="spbh" label="商品编号" sortable width="180"></el-table-column>
          <el-table-column prop="spmc" label="商品名称" width="180"></el-table-column>
          <el-table-column prop="ggxh" label="规格型号" width="180"></el-table-column>
          <el-table-column prop="ys" label="颜色" width="80"></el-table-column>
          <el-table-column prop="kcs" label="库存数" width="120"></el-table-column>
          <el-table-column prop="sldw" label="数量单位" width="120"></el-table-column>
          <el-table-column prop="bz" label="备注" width="120"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="list_page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    name:'purchaseMaterial',
    data(){
      return{
        //控制开关
        show_sfbf:false,
        show_sftl:false,

        form_data:{
          qgsj:'',
          ylsj:'',
          ysddh:'',
          ysscdh:'',
          sftl:'',
          sfbf:'',
          tldh:'',
          sqsy:''
        },

        tableData:[
          {
            spbh:'2220200120',
            spmc:'商品1',
            ggxh:'12*12',
            ys:'红色',
            kcs:'0',
            sldw:'只',
            bz:'备注信息',
          }
        ]
      }
    },
  }
</script>
