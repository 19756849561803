<template>
  <div class="content_condition" id="accountingId">
    <ul class="condition_ul">
      <li class="item">
        <el-checkbox v-model="whetherMaterialCost" @change="costMaterialChange()">物料费用</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherGrossMaterialCost" @change="costGrossChange()">预计物料费用</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherMaterialProcessing">物料加工</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherPersonCost">人工成本</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherAdministrativeFee">管理费</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherFabricatingCost">制造费</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherOtherExpense">其他费用</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherClaimExpense">报销费用</el-checkbox>
      </li>
      <li class="item">
        <el-checkbox v-model="whetherScrap">报废数也计成本</el-checkbox>
      </li>
      <!-- <li class="item">
        <el-checkbox v-model="whetherAffiliation">含归属子级成本</el-checkbox>
      </li> -->
    </ul>
    <div>
       <span class="cost_operation" @click="getPurchaseMrpOrMrpData">立即运算</span>
    </div>
    <div class="left_table billing_table">
      <el-table :data="processList" border height="100%" style="width: 100%">
        <el-table-column prop="processName" label="工序名称" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="workNum" label="报工数量" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="materialCost" label="物料成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="staffCost" label="人员成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="administrativeCost" label="管理成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fabricatingCost" label="制造成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="otherCost" label="其他成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="expenseCost" label="报销成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="totalCost" label="总计成本" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="singleCost" label="单个成本" width="130" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script>

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入相关api接口
  import logApi from '@/network/log/meslog.js'; //日志相关接口
  import mesApi from '@/network/production/production.js';//生产相关接口

  export default{
    name:'detail_operateLog',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        logMesgger:[],

        //相关工序
        processList:[],

        //成本核算条件
        whetherMaterialCost: true, //物料费用
        whetherGrossMaterialCost: false, //物料费用是否含毛材料费用
        whetherMaterialProcessing: false, //物料加工
        whetherPersonCost:true,//人工成本
        whetherAdministrativeFee: true, //管理费
        whetherFabricatingCost: true, //制造费
        whetherOtherExpense: false, //其他费用
        whetherClaimExpense: true, //报销费用
        whetherScrap:true,//是否含报废数
        // whetherAffiliation:true,//是否包含子级费用
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },

    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "8") {
           this.getretrunCost()
        }
      },
    },
    methods:{

      //初始化获取数据
      getretrunCost(){
        let data={
          mesId:this.mesDetailData.id
        }
        this.loading = this.commonJsExtend.customLoading("#accountingId", 4, '成本核算数据加载中,请稍后...');
        mesApi.findCostAccounting(data).then(res=>{
          if(res.code==200){
            this.processList=res.data
          }else{
            this.$message({
             type: 'error',
             message: res.message,
             duration: this.elDuration
            })
          }
          this.loading.close()
        })
      },

      getPurchaseMrpOrMrpData(){
        let data={
          mesId:this.mesDetailData.id
        }
        //是否需要物料费用
        if(this.whetherMaterialCost){
          data.whetherMaterialCost=1
        }else{
          data.whetherMaterialCost=0
        }
        //毛材料成本
        if(this.whetherGrossMaterialCost){
          data.whetherGrossMaterialCost=1
        }else{
          data.whetherGrossMaterialCost=0
        }
        //人工成本
        if(this.whetherPersonCost){
          data.whetherPersonCost=1
        }else{
          data.whetherPersonCost=0
        }
        //是否需要物料加工
        if(this.whetherMaterialProcessing){
          data.whetherMaterialProcessing=1
        }else{
          data.whetherMaterialProcessing=0
        }
        //是否需要管理费
        if(this.whetherAdministrativeFee){
          data.whetherAdministrativeFee=1
        }else{
          data.whetherAdministrativeFee=0
        }
        //是否需要制造费
        if(this.whetherFabricatingCost){
          data.whetherFabricatingCost=1
        }else{
          data.whetherFabricatingCost=0
        }
        //是否需要其他费用
        if(this.whetherOtherExpense){
          data.whetherOtherExpense=1
        }else{
          data.whetherOtherExpense=0
        }
        //是否需要报销费用
        if(this.whetherClaimExpense){
          data.whetherClaimExpense=1
        }else{
          data.whetherClaimExpense=0
        }
        //报工数是否包含报废数
        if(this.whetherScrap){
          data.whetherScrap=1
        }else{
          data.whetherScrap=0
        }
        //是否需要包含子级费用
        // if(this.whetherAffiliation){
        //   data.whetherAffiliation=1
        // }else{
        //   data.whetherAffiliation=0
        // }
        this.loading = this.commonJsExtend.customLoading("#accountingId", 4, '成本核算中,请稍后...');
        mesApi.costAccounting(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:"success",
              message:"操作成功",
              duration: this.elDuration
            })
            this.processList=res.data
          }else{
            this.$message({
             type: 'error',
             message: res.message,
             duration: this.elDuration
            })
          }
          this.loading.close()
        })

      },
      costMaterialChange(){
        if(this.whetherMaterialCost){
          this.whetherGrossMaterialCost=false
        }

      },
      costGrossChange(){
        if(this.whetherGrossMaterialCost){
          this.whetherMaterialCost=false
        }
      }

    },
  }
</script>

<style lang="less" scoped="scoped">

  .content_condition {
    padding: 10px;
    border: 1px solid @borderColor;


    .condition_ul {
      display: flex;

      .item {
        padding: 0 10px;

        .operation {
          cursor: pointer;
          color: @theme;
        }

        .operation:hover {
          color: blue;
        }
      }
    }
  }
  .cost_operation{
    display: block;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    color: @theme;
  }
  .cost_operation:hover {
          color: blue;
        }
  //表格内容
  .left_table {
    height: 35vh;
  }
</style>
