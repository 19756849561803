<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产领料组件
	开始时间：2021-05-17
	开发人员：刘巍骏
	最后修改：2021-05-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="">
    <el-dialog title="生产入库" :visible.sync="mesProInventory" :before-close="cancleProBox" width="1000px" append-to-body>
      <div class="mesProInventory billingcreate" id="InventoryProBox">
        <!-- 基本信息框 -->
        <div class="billingcreate_form">
          <ul class="form_ul">
            <li>
              <div class="form_title">
                单据编号:
              </div>
              <div class="form_date">
                <input v-model="mesProInventoryFormData.proMaterialNum" disabled type="text">
              </div>
            </li>
            <li>
              <div class="form_title">
                生产单号:
              </div>
              <div class="form_date">
                <input v-model="mesProInventoryFormData.productionNo" disabled type="text">
              </div>
            </li>
            <li>
              <div class="form_title">
                入库仓库:
              </div>
              <div class="form_date" @click="SHOW_WAREHOUSEBOX(true)">
                <input v-model="mesProInventoryFormData.warehouseName" type="text" placeholder="请选择仓库">
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                入库人员:
              </div>
              <div class="form_date" @click="SHOW_PERSONNELBOX(true)">
                <input v-model="mesProInventoryFormData.inventoryUserName" type="text" placeholder="请选择领料人">
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
          </ul>
        </div>

        <!-- 产品内容框 -->
        <div class="ProMaterialTable billing_table">
          <el-table :data="mesInventoryProData" row-key="id" height="100%" border style="width: 100%">
            <!-- 内容 -->
            <el-table-column prop="productInfo.product_code" sortable label="货品编号" width="150"></el-table-column>
            <el-table-column prop="productInfo.name" label="货品名称" width="200"></el-table-column>
            <el-table-column prop="productInfo.specifications" label="规格型号" width="150"></el-table-column>
            <el-table-column prop="plan_num" label="计划入库数" width="120"></el-table-column>
            <el-table-column prop="proNumber" label="本次入库" width="120">
              <!-- 自定义表头内容 -->
              <template slot="header" slot-scope="scope">
                <span class="span_color_red">本次入库</span>
              </template>
              <!-- 自定义表格内容 -->
              <template slot-scope="scope">
                <input class="inputNum" type="number" placeholder="入库数"
                  v-model="proNumber" oninput="this.value=this.value.replace(/[^0-9]/g,'')" />
              </template>
            </el-table-column>
            <el-table-column prop="calculate_name" label="计量单位" min-width="100"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commitManageMaterial(0)">提交</el-button>
        <!-- <el-button type="primary" size="mini" @click="commitManageMaterial(1)">提交并审核</el-button> -->
        <el-button size="mini" @click="cancleProBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 仓库选择组件 -->
    <warehouseList @SelectedData="getWarehouseData"></warehouseList>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData"></personnelList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件

  // 导入方法
  import api from '@/network/stock/stock.js'; //出入库单相关接口
  import product from '@/network/datum/product.js'; //货品相关接口
  import stockApi from '@/network/stock/stock.js';//生产生成相关出入库单相关接口
  import billsCommApi from '@/network/billsComm/billsComm.js'; //单据公共接口

  export default {
    name: 'materialGet',
    props: {
      //控制显示生产领料弹框
      mesProInventory: {
        type: Boolean,
        default: false
      },
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      },
    },
    data() {
      return {
        //当前组件数据
        mesProInventoryFormData: { //生产入库表单数据
          proMaterialNum: '', //入库单单据编号
          productionId: '', //生产单id
          productionNo: '', //生产单编号
          warehouseId: '', //仓库id
          warehouseName: '', //仓库名称
          inventoryUserId:'',// 入库人员id
          inventoryUserName:'',// 入库人员名称
        },
        mesInventoryProData: [], //生产入库产品数据
        proNumber:0,//本次入库数量

        // loading框
        loading: '',
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
      })
    },
    mounted() {
      // 初始化vuex数据字典数据
      this.initVuexData();
    },
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
      ]),
      ...mapActions([
        'getWarehouseConfig', //获取仓库配置
      ]),

      /* 初始化界面数据字典数据 */
      async initVuexData(){
        //仓库数据
        if(this.WarehouseConfig.length == 0){
          await this.getWarehouseConfig();
        }
        // 初始化领料数据
        this.initInventoryFormData();
        //初始化物料表格数据
        this.initInventoryTableData();
      },

      /* 初始化领料数据 */
      initInventoryFormData() {
        //获取生产领料单单据编号
        this.getBillsNumber();
        //获取单据表单信息
        this.mesProInventoryFormData.productionId = this.mesDetailData.id; //生产单id
        this.mesProInventoryFormData.productionNo = this.mesDetailData.bills_no; //生产单编号
        //获取默认入库人
        this.mesProInventoryFormData.inventoryUserId = this.UserInfo.userId;//用户id
        this.mesProInventoryFormData.inventoryUserName = this.UserInfo.user_name;//用户名称
        //获取默认仓库
        this.WarehouseConfig.forEach((item,index)=>{
          if(item.is_main == 0){
            this.mesProInventoryFormData.warehouseId = item.id;//仓库id
            this.mesProInventoryFormData.warehouseName = item.name;//仓库名称
          }
        })
      },

      /* 初始化物料表格数据*/
      initInventoryTableData(){
        //清空表格数据
        this.mesInventoryProData = [];
        //获取表格数据
        this.mesInventoryProData.push(this.mesDetailData);
        // console.log(this.mesInventoryProData);
      },

      /* 获取单据编号*/
      getBillsNumber(data, billsType) {
        //定义后端接受参数
        let typeData = {
          templateCode:'DOC-M-015',
          sign:2
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#InventoryProBox", 4, '单据编码生成中,请稍候...');
        //发送请求
        billsCommApi.findBillsCodeByTempBillsCode(typeData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取单据类型id
            this.mesProInventoryFormData.proMaterialNum = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 提交生产物料数据 */
      commitManageMaterial(type) {

        if (!!!this.proNumber) {
          this.$message({
            type: 'warning',
            message: '请填写本次入库数量!',
            duration: this.elDuration
          })
          return
        }
        let data={
          id:this.mesProInventoryFormData.productionId,
          number:this.proNumber,
          houseId:this.mesProInventoryFormData.warehouseId,
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
        stockApi.finishedGoodsReceiptDoc(data).then(res=>{
          this.loading.close();
          if(res.code == 200){
            this.$message({
              type:'success',
              message:res.message,
              duration:this.elDuration
            })
            this.cancleProBox()
            this.$confirm('生产成品入库单已生成是否跳转到生产成品入库单界面', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      this.$router.push({
                          path: "/warehouse_view?inventoryType=0&inventoryBillCode=DOC-M-015",
                         })
                    }).catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消跳转'
                      });
                    });
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }

        })
        // console.log(this.mesDetailData);
        // //定义出入库单主表数据
        // let depotMaindata = {
        //   bills_no: this.proMaterialFormData.proMaterialNum, //单据编号
        //   billing_type_id: this.proMaterialFormData.proMateriaTypelId, //单据类型id
        //   create_user: this.UserInfo.user_id, //创建用户
        //   bills_date: this.commonJsExtend.getDateTime(new Date(0), ), //单据日期
        //   status_id: 1, //节点id
        //   process_id: 1, //流程id
        //   depot_classify: 0, //出入库分类(0代表入库单)
        //   customer_id: this.mesDetailData.customer_id, //客户id
        //   outin_depot_sign: 1, //出入库标识(1代表出库)
        //   in_depot_id: this.proMaterialFormData.warehouseId, //入库仓库
        //   in_depot_time: '', //入库时间
        //   sign: 1, //关联单据标识(1代表生产)
        //   relevance_bills: this.mesDetailData.id, //关联单据id
        // }
        // //定义出入库单库存数量数据
        // let depotProNumData = []
        // // 处理产品数据
        // console.log(this.selMaterialData);
        // this.selMaterialData.forEach((item, index) => {
        //   let materialItem = {
        //     product_info_id: item.product.id, //货品id
        //     name: item.product.product_info_name, //货品名称
        //     specifications: item.product.specifications, //规格型号
        //     calculate_type: this.proCurrentUnit.calculate_type, //计量单位
        //     number: this.proNumber, //本次数量(入库数)
        //   }
        //   depotProNumData.push(materialItem);
        // })
        // // 定义新增库存单据对象
        // let depotData = {
        //   billingName: '', //单据类型名称
        //   depotMain: JSON.stringify(depotMaindata), //	基本信息
        //   depotMainProNum: JSON.stringify(depotProNumData), //出入库单-产品关联
        //   nodeHours: '', //工时信息
        //   processId: 1, //流程id
        //   remark: '', //备注信息
        // }
        // // console.log(depotData);
        // // return
        // //发送添加出入库单请求
        // api.addDepotMain(depotData).then(res => {
        //   if (res.code == 200) {
        //     this.$message({
        //       type: 'success',
        //       message: '入库单生成成功!',
        //       duration: this.elDuration
        //     })
        //     // 关闭弹框
        //     this.$parent.closeProMaterialBox();
        //   } else {
        //     this.$message({
        //       type: 'error',
        //       message: res.message,
        //       duration: this.elDuration
        //     })
        //   }
        // })
      },

      /* 获取表格已选数据 */
      getSelectedData(selection, row) {
        // 将已选数据存入data
        this.selMaterialData = selection;
      },

      /* 全选时触发 */
      getSelectedAllData(selection) {
        this.selMaterialData = selection;
      },

      /* 获取所选仓库数据 */
      getWarehouseData(data) {
        this.proMaterialFormData.warehouseId = data.id; //获取仓库id
        this.proMaterialFormData.warehouseName = data.name; //获取仓库明后才能
      },

      /* 获取领料人弹框数据 */
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        this.proMaterialFormData.inventoryUserId = result.idStr; //id字符串
        this.proMaterialFormData.inventoryUserName = result.nameShowStr; //名称字符串
      },

      /* 取消新增退料弹框 */
      cancleProBox() {
        this.$parent.closeProMaterialBox();
      },
    },
    components: {
      warehouseList,
      personnelList
    }
  }
</script>

<style lang="less">
  .mesProInventory {
    height: 40vh;
  }

  .ProMaterialTable {
    height: 80%;
  }
</style>
