<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.入库产品
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="detailInIventory">
    <!-- 入库产品头部按钮 -->
    <div class="detailtab_head">
      <el-button type="primary" size="small" @click="showProMaterialBox">生产入库</el-button>
    </div>
    <!-- 入库产品表格数据 -->
    <div class="detailtab_table billing_table">
      <el-table :data="inInventoryData" height="100%" border style="width: 100%">
        <el-table-column type="index" label="序号" sortable width="60"></el-table-column>
        <!-- 操作 -->
       <!-- <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column> -->
        <!-- 数据内容 -->
        <el-table-column prop="billsNo" label="编号" sortable width="170"></el-table-column>
        <el-table-column prop="depotProNum[0].name" label="名称" width="120"></el-table-column>
        <el-table-column prop="depotProNum[0].specifications" label="规格" width="150"></el-table-column>
        <el-table-column prop="saleOrderMain.isSubmit" label="状态" sortable width="80">
          <template slot-scope="scope">
            <el-button v-if="scope.row.isSubmit == 0" type="info" size="mini">未提交</el-button>
            <el-button v-if="scope.row.isSubmit == 1" type="warning" size="mini">审核中</el-button>
            <el-button v-if="scope.row.isSubmit == 2" type="danger" size="mini">已驳回</el-button>
            <el-button v-if="scope.row.isSubmit == 3" type="primary" size="mini">复审中</el-button>
            <el-button v-if="scope.row.isSubmit == 4" type="success" size="mini">已审核</el-button>
            <el-button v-if="scope.row.isSubmit == 5" type="success" size="mini">已完成</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orderCount" label="计划入库数" width="120"></el-table-column>
        <el-table-column prop="productCount" label="验收合格" width="80"></el-table-column>
        <el-table-column prop="endPlanDate" label="不合格" width="120"></el-table-column> -->
        <el-table-column prop="depotProNum[0].number" label="入库数" width="150"></el-table-column>
        <el-table-column prop="shopNumber" label="备注" min-width="150"></el-table-column>
      </el-table>
    </div>

    <!-- 生产入库组件 -->
    <inInventoryProduct :mesProInventory = "mesProInventory" :mesDetailData="mesDetailData" v-if="mesProInventory"></inInventoryProduct>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import inInventoryProduct from './item_compontent/inInventory_product.vue';//生产入库组件

  //导入方法
  import api from '@/network/production/production.js';//生产相关接口

  export default{
    name:'detail_inInventory',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        //当前组价数据
        inInventoryData:[],//入库列表数据

        // 控制开关
        mesProInventory:false,//控制显示入库产品组件

        // loading框
        loading:"",
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },
    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "5") {
          // 获取工序数据
          this.getInInventoryData();
        }
      },
    },
    methods:{
      /* 获取生产入库产品列表 */
      getInInventoryData(){
        //定义传入后端的数据
        let data = {
          id:this.mesDetailData.id
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#detailInIventory", 4, '入库产品信息更新中,请稍后...');
        // 发送请求
        // api.findDeoptProListById(data).then(res=>{
           api.findFinishedProductsById(data).then(res=>{
          this.loading.close();
          //判断接口返回值
          if(res.code == 200){
            //获取入库产品列表
            this.inInventoryData = res.data;
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
        console.log(this.inInventoryData)
      },

      /* 控制显示生产入库弹框 */
      showProMaterialBox(){
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "该单据还未审核，不可使用该功能",
            duration: this.elDuration
          })
          return
        }
        this.mesProInventory = true;
      },

      /* 关闭生产入库弹框 */
      closeProMaterialBox(){
        this.mesProInventory = false;
      },
    },
    components:{
      inInventoryProduct
    }
  }
</script>

<style>
</style>
