<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.操作日志
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="reportDetailBox">
    <!-- 操作日志头部按钮 -->
    <!-- <div class="detailtab_head">
      <el-button type="primary" size="small">新增日志</el-button>
    </div> -->
    <!-- 操作日志表格数据 -->
    <div class="detailtab_table billing_table">
      <el-table :data="logMesgger" height="100%" border show-summary style="width: 100%">
        <!-- 操作 -->
        <!-- <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column> -->
        <!-- 数据内容 -->
        <el-table-column prop="operateUserName" label="操作人" sortable width="200"></el-table-column>
        <el-table-column prop="operateContent" label="操作内容" show-overflow-tooltip width="400"></el-table-column>
        <el-table-column prop="operateTime" label="操作时间" width="180"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入相关api接口
  import logApi from '@/network/log/meslog.js'; //生产相关接口

  export default{
    name:'detail_operateLog',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        logMesgger:[]
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },

    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "7") {
          // 获取工序数据
          this.getLogMessage();
        }
      },
    },
    methods:{
      getLogMessage(){
        let data={
          id:this.mesDetailData.id
        }
        //加载loading
        this.loading = this.commonJsExtend.customLoading("#reportDetailBox", 3, '获取相关日志信息中,请稍候...');
        logApi.queryRelatedLog(data).then(res=>{
          this.loading.close()
          if(res.code==200){
            this.logMesgger=res.data
          }else{
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      }
    },
  }
</script>

<style>
</style>
