<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.加工费用
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="detailexpend">
    <!-- 加工费用头部按钮 -->
    <div class="detailtab_head">
      <!-- <el-button type="primary" size="small" @click="show_paymoney=true">提请付款</el-button> -->
      <el-button type="primary" size="small" @click="showAdd()">增加费用</el-button>
    </div>
    <!-- 加工费用表格数据 -->
    <div class="detailtab_table billing_table">
      <el-table :data="tableData_material" height="100%" border show-summary style="width: 100%">
        <!-- 操作 -->
        <!-- <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column> -->
        <!-- 数据内容 -->
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-delete icon" title="删除" @click="delectExpend(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="bills_no" label="单据编号" sortable width="150"></el-table-column>
        <el-table-column prop="bills_date" label="单据日期" width="120"></el-table-column>
        <el-table-column prop="billing_type_name" label="单据类型" width="150"></el-table-column>
        <el-table-column prop="total_amount" label="费用总金额" width="120"></el-table-column>
        <el-table-column prop="is_submit" label="状态" width="120">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_submit == 1" type="warning" size="mini">未审核</el-button>
            <el-button v-if="scope.row.is_submit == 4" type="success" size="mini">已审核</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="报销原因" width="180"></el-table-column>
      </el-table>
    </div>

    <!-- 弹框 -->
    <!-- 提请付款 -->
    <el-dialog title="提请付款" :visible.sync="show_paymoney" width="450px" append-to-body>
      <!-- 提请付款表单 -->
      <!-- 表单总框 -->
      <div class="formAll">
        <el-form ref="form" :model="sizeForm" label-width="100px" size="mini">
          <el-form-item label="供应商:">
            <el-input placeholder="请输入供应商" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="合计费用:">
            <el-input placeholder="请输入合计费用" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="已付费用:">
            <el-input placeholder="请输入已付费用" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="结算帐号:">
            <el-input placeholder="请输入结算帐号" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="本次支付:">
            <el-input placeholder="请输入结算帐号" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="textarea" :rows="3" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="show_paymoney = false">提交</el-button>
        <el-button type="primary" size="mini" @click="show_paymoney = false">提交并审核</el-button>
        <el-button size="mini" @click="show_paymoney = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 增加费用 -->
    <el-dialog title="增加费用" :visible.sync="show_addmoney" width="450px"  append-to-body>
      <!-- 增加费用表单 -->
      <!-- 表单总框 -->
      <div class="formAll">
        <el-form ref="form" :model="reimbursement" label-width="100px" size="mini">
          <el-form-item label="报销原因:">
            <el-input placeholder="请输入报销原因" v-model="reimbursement.reason"   size="mini"></el-input>
          </el-form-item>
          <el-form-item label="费用分类:">
            <el-input placeholder="请输入所需费用" v-model="reimbursement.classificationName" @click.native="showCostTypeComponent()" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="工序选择:">
            <el-input placeholder="请输入报销原因" v-model="reimbursement.processName" @click.native="showProcessComponent()"   size="mini"></el-input>
          </el-form-item>
          <el-form-item label="费用金额:">
            <el-input placeholder="请输入费用金额" v-model="reimbursement.money" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="备注说明:">
            <el-input type="textarea" :rows="3" v-model="reimbursement.remark" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commitReimbursement()">提交</el-button>
        <!-- <el-button type="primary" size="mini" @click="show_addmoney = false">提交并审核</el-button> -->
        <el-button size="mini" @click="show_addmoney = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 工序查询 -->
    <el-dialog title="指定相关工序"  :visible.sync="show_process" width="700px" append-to-body>
        <template >
          <div id="process">
          <el-table
            ref="multipleTable"
            :data="tableDataProcess"
            tooltip-effect="dark"
            style="width: 100%"
            @select="selectProcess"
            @select-all="selectProcessAll"
           >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="工序名称"
              width="180">
              <template slot-scope="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column
              prop="userName"
              label="负责人名称"
              width="180"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="负责部门"
              width="180"
              show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px">
            <el-button @click="checkProcess()" >确定</el-button>
            <el-button  @click="show_process=false">取消</el-button>
          </div>
          </div>
        </template>
    </el-dialog>


    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData" v-if="show_costTypeComponent" :conditionObj="costTypeConditionObj" >
    </costTypeTree>
  </div>
</template>

<script>
   // 导入vuex
   import {
     mapMutations,
     mapState,
     mapActions
   } from 'vuex'

  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件
  import costApi from '@/network/cost/account.js';//报销单相关接口
  import mesApi from '@/network/production/production.js'; //生产相关接口
  export default{
    name:'detail_charges',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        // 测试用
        input:'',
        textarea:'',
		sizeForm:{
      reason:'',
    },

        // 控制开关
        show_paymoney:false,
        show_addmoney:false,
        show_process:false,//指定相关工序开关
        show_costTypeComponent:false,//控制费用分类组件弹框

        //报销模块
        reimbursement:{
          reason:'',//报销原因
          classification:'',//费用分类id
          classificationName:'',//费用分类名称
          money:'',//费用金额
          remark:'',//备注说明
        },
        costTypeConditionObj: {}, //费用分类查询条件

        tableData_material:[],
        tableDataProcess:[],//生产单工序相关信息
        selectTableDataProcess:[],//已选中工序信息
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },
    watch:{
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "6") {
          // 获取费用单数据
          this.queryingReimbursement();
        }
      },
    },
    methods:{
      ...mapMutations([
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
      ]),

      //分类选择组件控制
      showCostTypeComponent(){
        this.show_costTypeComponent=true
        this.SHOW_COSTTYPEBOX(true);
      },
      //已选中信息
      selectProcess(selection, row){
        //设置单选
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(row)
        this.selectTableDataProcess=row
      },
      //全选
      selectProcessAll(selection){
        this.$message({
          type:"warning",
          message:"目前暂不支持全选"
        })
        this.$refs.multipleTable.clearSelection();
      },
      //已选中信息回显
      checkProcess(){
        this.reimbursement.processName=this.selectTableDataProcess.name
        this.reimbursement.processId=this.selectTableDataProcess.id
        this.show_process=false

      },

      //工序选择组件控制
      showProcessComponent(){
        //定义传入后端的数据
        let data = {
          mesMainId: this.mesDetailData.id, //生产单id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#process", 4, '工序选择加载中,请稍后...');

        // 发送请求
        mesApi.findMesProcessListByMesMainId(data).then(res => {
          // 判断返回结果
          if (res.code == 200) {
            console.log(res.data)
            //关闭loadign框
            this.loading.close();
            this.tableDataProcess = res.data;
            this.show_process=true

          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
            this.loading.close();
          }
        })

      },

      //查询相应报销单信息
      queryingReimbursement(){
        let data={
          id:this.mesDetailData.id
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#detailexpend", 4, '报销单信息更新中,请稍后...');
        costApi.queryingReimbursement(data).then(res=>{
          if (res.code == 200) {
            //获取单据类型id
            this.tableData_material = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          this.loading.close()
        })

      },

      //删除相关费用单
      delectExpend(data){
        if(data.is_submit==4){
          this.$message({
            type:"warning",
            message:"该单已审核不能删除"
          })
          return
        }
        let delData={
          id:data.id
        }
        this.$confirm("您确定要删除该条数据吗？删除后不可恢复!,请确认", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.loading = this.commonJsExtend.customLoading("#detailexpend", 4, '数据删除中,请稍后...');
          costApi.delectMesExpend(delData).then(res=>{
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "删除成功",
                duration: this.elDuration
              })
              this.loading.close()
              this.queryingReimbursement()
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close()
            }

          })
        }).catch(()=>{
          this.$message({
              type: 'info',
              message: '已取消返工',
              duration: this.elDuration
            });
        })

      },

      /*控制费用弹框*/
      showAdd(){
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "该单据还未审核，不可使用该功能",
            duration: this.elDuration
          })
          return
        }
        this.show_addmoney=true
      },

      cancleDialogOperate(){
        this.show_costTypeComponent=false
      },

      //提交费用报销单
      commitReimbursement(){
        if(!!!this.reimbursement.reason){
          this.$message({
            type: 'warning',
            message: '请填写报销原因!',
            duration: this.elDuration
          })
          return
        }
        if(!!!this.reimbursement.classification){
          this.$message({
            type: 'warning',
            message: '请填写本次费用分类!',
            duration: this.elDuration
          })
          return
        }
        if(!!!this.reimbursement.money){
          this.$message({
            type: 'warning',
            message: '请填写本次报销金额!',
            duration: this.elDuration
          })
          return
        }
        let data={
          id:this.mesDetailData.id,//生产单id
          money:this.reimbursement.money,//报销金额
          classification:this.reimbursement.classification,//费用分类id
          reason:this.reimbursement.reason,//备注
        }
         if(!!this.reimbursement.processId){
           data.resProcessId=this.reimbursement.processId
         }
        if(!!this.reimbursement.remark){
          data.remark=this.reimbursement.remark
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mesMaterialGetBox", 4, '单据生成中,请稍候...');
        costApi.generateExpenseDocument(data).then(res=>{
            this.loading.close();
          if(res.code == 200){
            this.$message({
              type:'success',
              message:res.message,
              duration:this.elDuration
            })
            this.show_addmoney=false
            this.reimbursement={}
            this.$confirm('费用报销单已生成是否跳转到费用报销单界面', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      this.$router.push({
                          path: "/expense_view?expenseType=2&expenseCode=DOC-C-001",
                         })
                    }).catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消跳转'
                      });
                      this.queryingReimbursement();
                    });
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })

      },

      /* 获取费用弹框选择数据 */
      getCostTypeData(data) {
        console.log(data)
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个费用',
            duration: this.elDuration
          })
          return
        }
        //获取选择的id数据
        this.reimbursement.classification = data[0].id;
        //获取选择的数据
        this.reimbursement.classificationName = data[0].name;
        this.show_costTypeComponent=false

      },



    },
    components: {
      costTypeTree,
    }
  }
</script>

<style>
</style>
