<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增工序表单弹框界面
	开始时间：2021-01-15
	开发人员：刘巍骏
	最后修改：2021-01-15
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 表单总框 -->
  <div class="formAll">
    <el-form ref="form" :model="sizeForm" label-width="100px" size="mini">
      <el-form-item label="备注:">
        <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="textarea"></el-input>
      </el-form-item>
      <el-form-item label="上传文件:">
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
    </el-form>

    <!-- 弹框 -->
    <!-- 图片查看 -->
    <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog> -->
  </div>
</template>

<script>
   export default {
     name:'technologyFileForm',
      data() {
        return {
          sizeForm: {
            name: '',
            region: '',
            date1: '',
            date2: '',
            delivery: false,
            type: [],
            resource: '',
            desc: ''
          }
        };
      },
      methods: {
        onSubmit() {
          console.log('submit!');
        }
      }
    };
</script>

<style>
</style>
