<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.物料管理
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="proMaterialDetail">
    <!-- 物料管理头部按钮 -->
    <div class="detailtab_head">
      <el-button type="info" size="small" v-if="companyparamlist.param353!=0" @click="addMaterialData(-1)">添加物料</el-button>
      <el-button type="primary" size="small" @click="showManageMaterial(0)">生产领料</el-button>
      <el-button type="danger" size="small" @click="showManageMaterial(1)">生产退料</el-button>
      <el-button type="primary" size="small" @click="showManageMaterial(4)">生产补料</el-button>
      <!-- <el-button type="success" size="small" @click="showManageMaterial(2)">用料登记</el-button> -->
      <el-button type="warning" size="small" @click="showManageMaterial(3)">提请采购</el-button>
    </div>
    <!-- 物料管理表格 -->
    <div class="detailtab_table billing_table">
      <el-table :data="materialData" :indent="18" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="product_info_id" height="100%" border style="width: 100%" :default-expand-all="true"  :header-cell-style="headerColor">
        <el-table-column label="操作" width="100" v-if="companyparamlist.param353!=0">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" title="修改" @click="updateMaterialData(scope.row)"></i>
            <i class="el-icon-delete icon" title="删除" @click="delectMaterialData(scope.row.id)"></i>
            <i class="el-icon-circle-plus-outline icon" title="添加子分类" @click="addMaterialData(scope.row.id)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="product_info_code" label="编号" width="150"></el-table-column>
        <el-table-column prop="product_info_name" label="物料名称" sortable width="200"></el-table-column>
        <el-table-column prop="specifications" label="物料规格" width="100"></el-table-column>
        <el-table-column prop="need_num" label="所需数量" width="80"></el-table-column>
        <el-table-column prop="need_total_num" label="所需总数量" width="80"></el-table-column>
        <el-table-column prop="already_req_num"  label="已请购数量" width="100"></el-table-column>
        <el-table-column prop="predict_get_num" label="预计领料数量" width="150"></el-table-column>
        <el-table-column prop="get_num" label="领料数" width="100"></el-table-column>
        <el-table-column prop="already_num" label="已用数" width="100"></el-table-column>
        <el-table-column prop="already_return_num" label="已退数" width="100"></el-table-column>
        <el-table-column prop="scrap_num" label="报废数" width="100"></el-table-column>
        <el-table-column prop="residue_num" label="剩余数" width="100"></el-table-column>
        <el-table-column prop="total_amount" label="所需费用" width="150"></el-table-column>
        <el-table-column prop="total_all_amount" label="所需总费用" width="150"></el-table-column>
        <el-table-column prop="consume_rate" label="报损率" width="80"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
      </el-table>
    </div>

    <!-- 弹框 -->
    <!-- 提请采购物料 -->
    <el-dialog title="采购物料选择" :visible.sync="show_materials" width="60%" append-to-body>
      <formlistPurchaseMaterial></formlistPurchaseMaterial>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer" >
        <el-button type="primary" size="mini" @click="show_materials = false">保存</el-button>
        <el-button type="primary" size="mini" @click="show_materials = false">保存并审核</el-button>
        <el-button size="mini" @click="show_materials = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 申请采购物料 -->
    <el-dialog title="申请采购物料" :visible.sync="show_applymaterials" width="60%" append-to-body>
      <formlistApplyMaterial></formlistApplyMaterial>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="show_applymaterials = false">保存</el-button>
        <el-button type="primary" size="mini" @click="show_applymaterials = false">保存并审核</el-button>
        <el-button size="mini" @click="show_applymaterials = false">取消</el-button>
      </span>
    </el-dialog>

    <!--新增物料组件 -->
    <materialAdd :materialAddBox="materialAddBox" :currentMaterial="currentMaterial" :addMaterialType="addMaterialType"
      @getMaterData="getMaterData" v-if="materialAddBox"></materialAdd>

    <!-- 生产领料/退料/补料/请购公共弹框 -->
    <materialGet :materialGetBox="materialGetBox" :mesDetailData="mesDetailData" :getMaterOperateType="operateManageType"
      :materialManageData="materialManageData" v-if="materialGetBox"></materialGet>

    <!-- 生产退料 -->
    <!-- <materialReturn :materialReturnBox="materialReturnBox" :mesDetailData="mesDetailData"
      :materialManageData="materialManageData" v-if="materialReturnBox"></materialReturn> -->

    <!-- 用料登记 -->
    <!-- <materialRegister :materialRegisterBox="materialRegisterBox" :mesDetailData="mesDetailData"
      :materialManageData="materialManageData" v-if="materialRegisterBox"></materialRegister>
 -->
    <!-- 提请采购 -->
    <!-- <materialPurchase :materialPurchaseBox="materialPurchaseBox" :mesDetailData="mesDetailData"
      :materialManageData="materialManageData" v-if="materialPurchaseBox"></materialPurchase> -->
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口

  // 导入组件
  import materialAdd from './item_compontent/material_add.vue'; //新增物料弹框
  import materialGet from './item_compontent/material_get.vue'; //生产领料弹框
  // import materialReturn from './item_compontent/material_return.vue'; //生产退料弹框
  // import materialRegister from './item_compontent/material_register.vue'; //用料登记弹框
  // import materialPurchase from './item_compontent/material_purchase.vue'; //提请采购组件

  import formlistPurchaseMaterial from '@/components/commComponent/formList/formlist_purchaseMaterial.vue'; //提请采购物料
  import formlistApplyMaterial from '@/components/commComponent/formList/formlist_applyMaterial.vue';

  export default {
    name: 'detail_material',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        //当前组件数据
        materialData: [], //所需物料数据
        materialManageData: [], //所需物料管理数据(弹框)
        operateManageType: -1, //物料操作类型(-1:新增物料  0:生产领料  1:生产退料 2:用料登记 3:提请采购 4:生产补料)
        operateAddManageParent: -1, //新增物料父级id

        //控制开关
        show_manageMaterial: false, //控制显示物料管理弹框(领料,退料,用料登记)

        //子组件数据
        materialAddBox: false, //控制显示新增物料弹框
        addMaterialType: -1, //生产单可添加物料的类型(生产单用)(0:只能添加指定属性的物料  1:可以添加所有属性物料)
        currentMaterial: -1, //当前操作物料id(-1代表新增,其他代表修改)
        materialGetBox: false, //控制显示生产领料弹框
        materialReturnBox: false, //控制显示生产退料弹框
        materialRegisterBox: false, //控制显示用料登记弹框
        materialPurchaseBox: false, //控制显示提请采购弹框

        //loading框
        loading: '', //loading框

        show_materials: false,
        show_applymaterials: false,

        oldmaterialData:{},//修改前的数据
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "0") {
          // 获取工序数据
          this.getMaterialData();
        }
      },
      //监听生产单详情数据
      mesDetailData(newVal) {
        this.getMaterialData();
      },
    },
    methods: {
      /* 获取生产物料数据 */
      getMaterialData() {
        let data = {
          mes_main_id: this.mesDetailData.id
        }
        // 加载loading框
        this.loading = this.commonJsExtend.customLoading("#proMaterialDetail", 4, '物料信息更新中,请稍后...');
        //发送请求
        api.findMesMaterialByBillId(data).then(res => {
          //关闭loading框
          this.loading.close()
          //判断接口返回结果
          if (res.code == 200) {
            console.log(res.data)
            this.materialData = res.data;

          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })

      },

      /*修改表头颜色*/
      headerColor({row, rowIndex,columnIndex}){
       if([6,7,8,9,10,11].includes(columnIndex)){
           return 'color: green  '
       }


      },

      /* 显示管理物料弹框type(0:生产领料  1:生产退料 2:用料登记 3:提请采购 4:生产补料) */
      showManageMaterial(type) {
        if(this.mesDetailData.is_submit!=4){
          this.$message({
            type: 'warning',
            message: "该单据还未审核，不可使用该功能",
            duration: this.elDuration
          })
          return
        }
        // 将操作类型存入data
        this.operateManageType = type;
        //将所需管理物料存入data
        this.materialManageData = this.materialData;
        //显示物料操作弹框
        this.materialGetBox = true;
        // //  判断操作类型
        // if (type == 0) { //生产领料
        //   //显示生产领料弹框
        //   this.materialGetBox = true;
        // } else if (type == 1) { //生产退料
        //   //显示生产退料弹框
        //   this.materialReturnBox = true;
        // } else if (type == 2) { //用料登记
        //   //显示用料登记弹框
        //   this.materialRegisterBox = true;
        // } else if (type == 3) { //提请采购
        //   this.materialPurchaseBox = true;
        // }
      },

      /* 添加物料信息parentid:父级id*/
      addMaterialData(parentid) {
        //将数据存入data
        this.operateAddManageParent = parentid; //父id
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为新增
        this.currentMaterial = -1;
      },

      /* 删除物料 */
      delectMaterialData(id) {
        this.$confirm('删除后的数据不可恢复, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            id: id,
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#proMaterialDetail", 4, '物料信息删除中,请稍后...');
          //发送删除请求
          api.deleteMesMaterial(data).then(res => {
            this.loading.close();
            //判断接口返回值
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "物料数据删除成功",
                duration: this.elDuration
              })
              // 获取物料列表数据
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消清除',
            duration: this.elDuration
          });
        });
      },

      /* 修改物料信息 */
      updateMaterialData(data) {
        //设置弹框类型为新增
        this.currentMaterial = data.id;

        //将老的数据先存放起来
        this.oldmaterialData=JSON.parse(JSON.stringify(data))
        //显示修改物料弹框
        this.materialAddBox = true;
      },

      /* 获取新增物料数据(添加物料) */
      getMaterData(data) {
        console.log(data);
        console.log(this.oldmaterialData)
        //判断数据合法性
        // if(){

        // }
        //定义传入后端的数据
        let materialData = {
          is_init: 1, //是否为初次添加物料
          mes_bom_parent_id: this.operateAddManageParent, //父id
          mes_main_id: this.mesDetailData.id, //生产单id
          product_info_id: data.proId, //物料id
          product_info_code: data.proCode, //物料编号
          product_info_name: data.name, //物料名称
          specifications: data.specifications, //规格型号
          calculate_id: data.unit, //计量单位id
          calculateName: data.unitName, //计量单位名称
          price: data.price, //成本价格
          consume_rate: data.consume_rate, //报损率
          need_num: data.number, //所需数量
          total_amount: data.cost, //总费用
          remark: data.remark, //备注
          machine_price:data.machine_price,//加工费单价
          valuation_num:data.valuation_num,//计价数
        }
        //获取总数量相关
        materialData = this.getMaterialTotalMsg(materialData);
        // 判断是新增还是修改
        if (this.currentMaterial == -1) { //新增
        let log="添加物料为: 物料编号:"+data.proCode+",物料名称:"+data.name+",规格型号:"+data.specifications
        +",计量单位:"+data.unitName+",成本价格:"+data.price+",报损率:"+data.consume_rate+",所需数量:"
        +data.number+",总费用:"+data.cost+",备注:"+data.remark+",加工费单价:"+data.machine_price
        +",计价数:"+data.valuation_num
        materialData.logOperation=log
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#proMaterialDetail", 4, '物料信息新增中,请稍后...');
          // 发送请求
          api.addMesMaterial(materialData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "物料添加成功",
                duration: this.elDuration
              })
              // 获取物料列表数据
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else { //修改
        let log="[修改前: 物料编号"+this.oldmaterialData.product_info_code+",物料名称:"+this.oldmaterialData.product_info_name
        +",规格型号:"+this.oldmaterialData.specifications+",计量单位:"+this.oldmaterialData.calculateName
        +",成本价格"+this.oldmaterialData.price+",报损率:"+this.oldmaterialData.consume_rate+",所需数量:"
        +this.oldmaterialData.need_num+",总费用:"+this.oldmaterialData.total_amount+",备注:"+this.oldmaterialData.remark+",加工费单价:"
        +this.oldmaterialData.machine_price+",计价数:"+this.oldmaterialData.valuation_num+"]"
        +"[修改后: 物料编号:"+data.proCode+",物料名称:"+data.name+",规格型号:"+data.specifications
        +",计量单位:"+data.unitName+",成本价格:"+data.price+",报损率:"+data.consume_rate+",所需数量:"
        +data.number+",总费用:"+data.cost+",备注:"+data.remark+",加工费单价:"+data.machine_price
        +",计价数:"+data.valuation_num+"]"
        materialData.logOperation=log
          materialData.id = this.currentMaterial;
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#proMaterialDetail", 4, '物料信息修改中,请稍后...');
          // 发送请求
          api.updateMesMaterial(materialData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "物料修改成功",
                duration: this.elDuration
              })
              // 获取物料列表数据
              this.getMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取物料总数量相关(单个) */
      getMaterialTotalMsg(data) {
        //获取所需总数量
        data.need_total_num = eval(data.need_num * this.mesDetailData.plan_num);
        //获取预计领料数量
        let breakageNum = Math.ceil(eval(data.need_total_num * data.consume_rate / 1000));
        data.predict_get_num = eval((parseInt(data.need_total_num) + parseInt(breakageNum)));
        //获取总费用
        data.total_all_amount = this.commonJsExtend.getDecimalsLen(eval(data.total_amount * this.mesDetailData
          .plan_num), 5);
        return data;
      },

      /* 关闭新增物料弹框 */
      closeMaterialBox() {
        this.materialAddBox = false;
      },

      /* 关闭生产领料弹框 */
      closeGetMaterialBox() {
        this.materialGetBox = false;
      },

      /* 关闭生产退料弹框 */
      closeReturnMaterialBox() {
        this.materialReturnBox = false;
      },

      /* 关闭用料登记弹框 */
      closeRegisterMaterialBox() {
        this.materialRegisterBox = false;
      },

      /* 关闭提请采购弹框 */
      closePurchaseMaterialBox() {
        this.materialPurchaseBox = false;
      }

      /* 回调新增数据 */
    },
    components: {
      materialAdd,
      materialGet,
      // materialReturn,
      // materialRegister,
      // materialPurchase,
      formlistPurchaseMaterial,
      formlistApplyMaterial
    }
  }
</script>

<style lang="less">
  // 物料管理弹框
  .manageMaterialBox {
    height: 40vh;
  }
</style>
