// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：生产详细日志相关
// 开始时间：2022-10-26
// 开发人员：王铭宇
// 最后修改：2022-10-26
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //生产详细日志查询
  queryRelatedLog(data){//根据计划单id获得相关操作日志
    return request({
      method:'POST',
      url:'/mesLog/queryRelatedLog',
      data
    })
  },
  }