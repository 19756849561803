<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.出库物料
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="">
    <!-- 出库物料头部按钮 -->
    <div class="detailtab_head">
      <el-button type="primary" size="small" @click="show_outInventoryform=true">新增物料</el-button>
    </div>
    <!-- 出库物料表格 -->
    <div class="detailtab_table billing_table">
      <el-table :data="tableData" height="100%" show-summary border style="width: 100%">
        <!-- 操作 -->
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="wlbh" label="物料编号" sortable width="150"></el-table-column>
        <el-table-column prop="wlmc" label="物料名称" width="120"></el-table-column>
        <el-table-column prop="ggxh" label="规格型号" width="120"></el-table-column>
        <el-table-column prop="ys" label="颜色" width="80"></el-table-column>
        <el-table-column prop="kykc" label="可用库存" width="80"></el-table-column>
        <el-table-column prop="sxsl" label="所需数量" width="80"></el-table-column>
        <el-table-column prop="ylls" label="已领料数" width="80"></el-table-column>
        <el-table-column prop="bcll" label="本次领料" width="80"></el-table-column>
        <el-table-column prop="jldw" label="计量单位" width="80"></el-table-column>
      </el-table>
    </div>

    <!-- 弹框 -->
    <!-- 新建出库物料弹框 -->
    <el-dialog title="新增出库物料" :visible.sync="show_outInventoryform" width="60%" append-to-body>
      <!-- 产品列表组件 -->
      <outInventory></outInventory>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="show_outInventoryform = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="show_outInventoryform = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入组件
  import outInventory from '@/components/commComponent/formList/outInventory.vue'

  export default{
    name:'detail_outInventory',
    data(){
      return{
        // 测试用
        input:'',
        
        //控制开关
        show_outInventoryform:false,//出库物料选择弹框

        tableData:[
          {
            wlbh:'pro20201215',
            wlmc:'物料名称',
            ggxh:'12*12',
            ys:'0',
            kykc:'0',
            sxsl:'0',
            ylls:'0',
            bcll:'0',
            jldw:'只',
          }
        ]
      }
    },
    components:{
      outInventory
    }
  }
</script>

<style>
</style>
