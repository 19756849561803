<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情.验收记录
	开始时间：2021-02-02
	开发人员：刘巍骏
	最后修改：2021-02-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="" id="checkRecordBox">
    <!-- 验收记录头部按钮 -->
    <div class="detailtab_head">
      <!-- <el-button type="primary" size="small" @click="showCheckRecordBox">新增记录</el-button> -->
    </div>
    <!-- 验收记录表格 -->
    <div class="detailtab_table billing_table" id="checkRecordBox">
      <!-- 表格 -->
      <el-table v-bind:data="checkRecordData" height="93%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <el-tooltip placement="right" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li @click="updateReportDetail(scope.row)">
                    <i class="el-icon-edit-outline operate_icon"  title="验收查看"></i>
                    <span class="left_padding_10">验收查看</span>
                  </li>
                 <!-- <li @click="reportForAcceptance(scope.row)">
                    <i class="el-icon-s-promotion operate_icon" title="验收"></i>
                    <span class="left_padding_10">验收</span>
                  </li> -->
                  <!-- <li @click="updateRework(scope.row)"> -->
                  <li @click="updateRework(scope.row)">
                    <i class="el-icon-warning-outline operate_icon" title="返工"></i>
                    <span class="left_padding_10">返工</span>
                  </li>
                  <li @click="deleteReportDetail(scope.row)">
                    <i class="el-icon-delete operate_icon" title="作废"></i>
                    <span class="left_padding_10">删除</span>
                  </li>
                </ul>
              </div>
              <i class="el-icon-more icon_color_red"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="process_name" label="工序名称" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="process_name" label="验收状态" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="info" size="mini" v-if="scope.row.check_state==0">未验收</el-button>
            <el-button type="success" size="mini" v-if="scope.row.check_state==1">已验收</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.check_state==2">已返工</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="operated_user_name" label="加工人员" sortable width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="check_user_name" label="检验人员" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="start_time" label="汇报时间" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="qualified_num" sortable label="合格数" width="120"></el-table-column>
        <el-table-column prop="scrap_num" sortable label="报废数" width="120"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="80" show-overflow-tooltip></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="list_page">
        <el-pagination @size-change="getReportPageSize" @current-change="getReportCurrentPage"
          :current-page="queryData.currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size="queryData.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="queryData.total">
        </el-pagination>
      </div>
    </div>

    <!-- 弹框 -->
    <!-- 新增/修改验收记录表单数据 -->
    <el-dialog :title="this.opreateAddOrUpdate==0?'新增记录':'修改记录'" :visible.sync="show_checkrecordform" width="600px"
      :before-close="closeCheckBox" append-to-body>
      <!-- 验收记录表单组件 -->
      <div class="billing_dialog_form">
        <ul class="form_ul">
          <li>
            <div class="title">
              <span class="titleText">验收时间:</span>
            </div>
            <div class="data">
              <el-date-picker class="data_input" size="small" @input="getTimeFormat($event,'checkTime')"
                v-model="checkRecordObj.checkTime" type="datetime" placeholder="选择日期"></el-date-picker>
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">验收人员:</span>
            </div>
            <div class="data" @click="getUserData">
              <el-input v-model="checkRecordObj.checkUser" size="small"
                oninput="this.value=this.value.replace(/\S+/g,'')" placeholder="请选择验收人">
                <i slot="suffix" class="el-input__icon el-icon-more"></i>
              </el-input>
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">合格数量:</span>
            </div>
            <div class="data">
              <el-input v-model="checkRecordObj.qualifiedNum" size="small" placeholder="请输入内容"></el-input>
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">计量单位:</span>
            </div>
            <div class="data">
              <el-input v-model="checkRecordObj.unit" size="small" disabled placeholder="请输入内容"></el-input>
              <!-- <el-select v-model="checkRecordObj.unit" value-key="id" size="small" placeholder="请输入内容">
                <el-option v-for="checkDate in productionUnit.prices" :label="checkDate.calculateName"
                  :value="checkDate.calculate_type" :key="checkDate.id"></el-option>
              </el-select> -->
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">不合格数:</span>
            </div>
            <div class="data">
              <el-input v-model="checkRecordObj.noQualifiedNum" size="small" placeholder="请输入内容"></el-input>
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">报废数:</span>
            </div>
            <div class="data">
              <el-input v-model="checkRecordObj.scrapNum" size="small" placeholder="请输入内容"></el-input>
            </div>
          </li>
          <li>
            <div class="title">
              <span class="titleText">返工数:</span>
            </div>
            <div class="data">
              <el-input v-model="checkRecordObj.returnNum" size="small" placeholder="请输入内容"></el-input>
            </div>
          </li>
          <li class="single_li">
            <div class="title">
              <span class="titleText">图纸:</span>
            </div>
            <div class="data">
              <el-upload :headers="headers" :file-list="fileList" :action="uploadFile" list-type="picture-card"
                :auto-upload="true" :on-success="uploadSuccess" :limit="1" :on-exceed="handleExceed"
                :on-remove="handleRemove" ref="upload">
                <i slot="default" class="el-icon-plus"></i><!-- +号 -->
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i> <!-- 图片放大-->
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </div>
          </li>
          <li class="single_li">
            <div class="title">
              <span class="titleText">备注:</span>
            </div>
            <div class="data">
              <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="checkRecordObj.remark"></el-input>
            </div>
          </li>
        </ul>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeCheckBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="addCheckRecord">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 图片放大弹窗-->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <!-- 负责人选择弹框组件 -->
    <personnelList @SelectedData="getSelUserData" v-if="show_personnelBox"></personnelList>

    <!-- 新增工序汇报组件 -->
    <reportCreate :show_report="show_report" :processRegects="processRegects"  :checkView="checkView" :viewChanges="viewChanges" :reportProcess="reportProcess" :operateType="operateType" :reportDetailData="reportDetailData"
      :mesDetailData="mesDetailData"></reportCreate>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入api接口
  import api from '@/network/production/production.js'; //生产相关接口
  import proApi from '@/network/datum/product.js'; //货品相关接口
  import dicApi from '@/network/system/dictionary';//数据字典相关方法

  // 导入组件
  import personnelList from "@/components/commComponent/list/list_personnel.vue"; //负责人组件
  import reportCreate from './item_compontent/report_create.vue'; //新增工序汇报组件

  export default {
    name: 'detail_checkrecord',
    props: {
      //生产单详情数据
      mesDetailData: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        // 当前组件数据
        opreateAddOrUpdate: 0, //0:新增 1：修改
        checkRecordData: [], //验收记录列表数据
        checkRecordObj: { //验收记录新增数据
          checkTime: '', //验收时间
          checkUser: '', //验收人员
          checkUserId: '', //验收人员id
          qualifiedNum: '', //合格数量
          unit: '', //计量单位
          unitId: '', //计量单位id
          noQualifiedNum: '', //不合格数量
          scrapNum: '', //报废数量
          isReturn: '', //是否需要返工
          returnNum: '', //返工数量
          returnProcess: '', //返工工序
          checkFilePath: '', //图纸
          remark: '', //备注
          id: '', //主键id，修改需要用到
          file_path: '', //图片地址
        },
        productionUnit: [], //生产计量单位

       //不良品项
       processRegects:[],

        //弹框请求数据
        queryData: {
          processId: -1, //工序id
          searchValue: '', //搜索框查询条件
          currentPage: 1, //当前页
          pageSize: 10, //页面大小
          total: 0, //总条数
        },

        //工序汇报组件相关
        reportDetailData:{},//工序汇报详情信息
        reportProcess: {}, //当前汇报工序
        operateType: 0, //工序汇报操作方式(0:新增  1:修改)
        show_report: false, //控制显示新增汇报弹框

        //文件相关
        filePath: '', //文件上传成功返回的地址
        fileName: '', //文件上传的名字
        url: '', //图片回显地址
        fileList: [], //图片回显
        uploadFile: baseUrl + '/mes/addMesMainRecordFile', //验收记录文件上传
        disabled: false, //文件上传显示按钮
        dialogImageUrl: '', //放大的图片路径
        dialogVisible: false, //放大图片弹窗
        //token
        headers: { //导入需要用到的token
          token: '',
        },

        //控制开关
        show_checkrecordform: false, //验收记录表单
        show_personnelBox: false, //控制选择负责人

        //控制查看的时候是否可以修改(0:可以 1:不可以)
        viewChanges:0,
        //判断该页面为验收页面
        checkView:1,
      }
    },
    computed: {
      ...mapState({
        pro_detail_tab_index: state => state.production.pro_detail_tab_index, //生产详情tab页下标
      })
    },
    watch: {
      // 监听详情页下标发生改变
      pro_detail_tab_index(newVal) {
        if (newVal == "4") {
          // 获取工序数据
          this.getProcessDetailData();
        }
      },
    },
    created() {
      this.headers.token = getCookie('token') //获取token
    },
    methods: {
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制员工弹框是否显示
      ]),

      // /* 获取验收列表信息 */
      // getCheckRecordData() {
      //   let data = {
      //     mes_main_id: this.mesDetailData.id, //生产单id
      //     delete_flag: 0 //删除标识
      //   }
      //   //加载loading框
      //   this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '验收记录数据更新中,请稍后...');
      //   //发送请求
      //   api.findMesMainRecordCondition(data).then(res => {
      //     //关闭loadign框
      //     this.loading.close();
      //     //验证请求结果
      //     if (res.code == 200) {
      //       this.checkRecordData = res.data;
      //     } else {
      //       this.$message({
      //         type: 'error',
      //         message: res.message,
      //         duration: this.elDuration
      //       })
      //     }
      //   })
      // },

      /* 显示验收信息弹框 */
      showCheckRecordBox() {
        //设置操作类型为新增
        this.opreateAddOrUpdate = 0;
        // 设置新增验收信息默认值
        this.checkRecordObj = { //验收记录新增数据
          checkTime: '', //验收时间
          checkUser: '', //验收人员
          checkUserId: '', //验收人员id
          qualifiedNum: 0, //合格数量
          noQualifiedNum: 0, //不合格数量
          unit: this.mesDetailData.calculate_name, //计量单位
          unitId: this.mesDetailData.calculate_id, //计量单位id
          scrapNum: 0, //报废数量
          isReturn: false, //是否需要返工
          returnNum: 0, //返工数量
          returnProcess: '', //返工工序
          checkFilePath: '', //图纸
          remark: '', //备注
        }
        // 显示验收信息弹框
        this.show_checkrecordform = true;
      },
      /* 获取页面大小*/
      getReportPageSize(value) {
        this.queryData.pageSize = value;
      },

      /* 获取当前页*/
      getReportCurrentPage(value) {
        this.queryData.currentPage = value;
      },

      /* 显示修改工序汇报弹框 */
      updateReportDetail(data){


        // if(data.check_state==1){
        //   this.$message({
        //     type: 'warning',
        //     message: "该单已经验收,不可以修改",
        //     duration: this.elDuration
        //   })
        //   return
        // }
        // if(data.check_state==2){
        //   this.$message({
        //     type: 'warning',
        //     message: "该单已经返工,不可以修改",
        //     duration: this.elDuration
        //   })
        //   return
        // }
        if(data.check_state==1){
          //当验收了之后查看只能预览
          this.viewChanges=1
          }else{
            this.viewChanges=0
          }

        //获取需要修改的信息
        this.reportDetailData = data;
        //获得相应工序信息
        this.reportProcess={
          processId:data.processId,//工序id
          name:data.process_name,
          workNum:data.processTotalTime,
          qualifiedNum:data.processQualifiedNum,
          residueNum:data.residue_num,
          totalTime: data.total_time, //合计用时
          workNum:data.work_num//派工数量
        }
        let rejectData={
          id:data.id,
          type:0
        }
        this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '新增验收记录数据中,请稍后...');
        api.findRejectByRecordId(rejectData).then(res=>{
         if(res.code==200){
           this.processRegects=res.data
           //设置操作类型为修改
           this.operateType = 1;
           //显示验收弹窗
           this.show_report = true;
         }
         else{
           // 显示提示信息
           this.$message({
             type: 'error',
             message: res.message,
             duration: this.elDuration
           })
         }
         this.loading.close()
        })

      },

      /*根据id修改返工状态*/
      updateRework(data){
        if(data.check_state==1){
          this.$message({
            type: 'warning',
            message: "该单已经验收,不可以返工",
            duration: this.elDuration
          })
          return
        }
        if(data.check_state==2){
          this.$message({
            type: 'warning',
            message: "该单已经返工，请不要重复返工",
            duration: this.elDuration
          })
          return
        }
        //获取需要修改的信息
        this.reportDetailData = data;
        //获得相应工序信息
        this.reportProcess={
          processId:data.processId,//工序id
          name:data.process_name,
          workNum:data.processTotalTime,
          qualifiedNum:data.processQualifiedNum,
          residueNum:data.residue_num,
          totalTime: data.total_time, //合计用时
          workNum:data.work_num//派工数量
        }
         this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '返工记录加载中,请稍后...');
        //
        dicApi.production.findAllProcessRejects().then(res=>{
          if(res.code==200){
            this.processRegects=res.data
            //显示工序汇报弹框
            this.show_report = true;
            //设置操作类型为返工
            this.operateType = 2;
            //显示验收弹窗
            this.show_report = true;
          }
          else{
            // 显示提示信息
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          this.loading.close()
        })


        // let dataStute={
        //   id:data.id,
        // }
        // this.$confirm("您确认要对该条数据进行返工吗?返工后状态不可恢复!", "提示", {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(()=>{
        //   //加载loading框
        //   this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '处理返工状态数据中,请稍后...');
        //   api.updateRework(dataStute).then(res=>{
        //     // 关闭loading框
        //     this.loading.close();
        //     //判断返回结果
        //     if (res.code == 200) {
        //       //获取最新列表信息
        //       this.getProcessDetailData();
        //     } else {
        //       this.$message({
        //         type: 'error',
        //         message: res.data,
        //         duration: this.elDuration
        //       })
        //     }
        //   })
        // }).catch(()=>{
        //   this.$message({
        //     type: 'info',
        //     message: '已取消返工',
        //     duration: this.elDuration
        //   });
        // })

      },

      //根据id删除汇报详情
      deleteReportDetail(data) {
        if(data.check_state==1 || data.check_state==2){
          this.$message({
            type: 'warning',
            message: "该单已经验收或返工,不可以删除",
            duration: this.elDuration
          })
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#reportDetailBox", 3, '数据删除中,请稍候...');
          //发送请求
          api.modifyProcessRecordDisable({
            id: data.id,
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              //获取新的列表数据
              this.getProcessDetailData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 关闭新增工序汇报弹框 */
      closeReportCreateBox() {
        //关闭弹框
        this.show_report = false;
        //获取新数据
        this.getProcessDetailData();
      },

      //点击编辑按钮打开编辑弹窗
      editCheckCord(data) {
        this.filePath = data.file_path;
        this.url = baseUrl + data.file_path.split("@@@")[0];
        if (data.file_path != null || data.file_path != '') { //文件路径不为空才取值
          let imgobj = {} //图片信息对象
          let imgurl = this.url;
          imgobj.name = '';
          imgobj.url = imgurl;
          this.fileList.push(imgobj);
        }
        this.opreateAddOrUpdate = 1; //修改
        this.show_checkrecordform = true; //打开弹窗
        this.checkRecordObj.checkTime = data.check_data; //验收日期
        this.checkRecordObj.checkUser = data.checkUserName; //验收人
        this.checkRecordObj.unit = data.calculateName; //计量单位
        this.checkRecordObj.qualifiedNum = data.qualified_num; //合格数量
        this.checkRecordObj.noQualifiedNum = data.not_qualified_num; //不合格数量
        this.checkRecordObj.returnNum = data.return_num; // 返工数
        this.checkRecordObj.scrapNum = data.scrap_num; //报废数
        this.checkRecordObj.remark = data.remark; //备注
        this.checkRecordObj.id = data.id; //主键id
        this.checkRecordObj.file_path = this.url;
      },

      /* 新增或修改验收信息数据 */
      addCheckRecord() {
        //判断是新增还是修改操作
        if (this.opreateAddOrUpdate == 0) { //增加
          this.fileList = []; //清空回显图片
          //判断数据合法性

          // 定义传入后端的值
          let data = {
            mes_main_id: this.mesDetailData.id, //生产单id
            check_data: this.checkRecordObj.checkTime, //验收时间
            check_id: this.checkRecordObj.checkUserId, //验收人员id
            check_user_name: this.checkRecordObj.checkUser, //验收人员名称
            calculate: this.checkRecordObj.unitId, //计量单位
            file_path: this.filePath, //凭证路径
            scrap_num: this.checkRecordObj.scrapNum, //报废数
            qualified_num: this.checkRecordObj.qualifiedNum, //合格数
            not_qualified_num: this.checkRecordObj.noQualifiedNum, //不合数
            is_return: 0, //是否返工
            return_num: this.checkRecordObj.returnNum, //返工数
            return_process: '', //返工工序id
            remark: this.checkRecordObj.remark, //备注
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '新增验收记录数据中,请稍后...');
          //发送请求
          api.addMesMainRecord(data).then(res => {
            // 关闭loading框
            this.loading.close();
            //判断返回结果
            if (res.code == 200) {
              // 关闭新增弹框
              this.show_checkrecordform = false;
              //获取最新列表信息
              this.getProcessDetailData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.opreateAddOrUpdate == 1) { //修改操作
          //循环计量单位匹配计量单位id
          this.productionUnit.prices.forEach(item => {
            if (item.calculateName == this.checkRecordObj.unit) {
              this.checkRecordObj.unit = item.calculate_type
            }
          })

          // 定义传入后端的值
          let data = {
            mes_main_id: this.mesDetailData.id, //生产单id
            check_data: this.checkRecordObj.checkTime, //验收时间
            check_id: this.checkRecordObj.checkUserId, //验收人员
            calculate: this.checkRecordObj.unit, //计量单位
            file_path: this.filePath, //凭证路径
            scrap_num: this.checkRecordObj.scrapNum, //报废数
            qualified_num: this.checkRecordObj.qualifiedNum, //合格数
            not_qualified_num: this.checkRecordObj.noQualifiedNum, //不合数
            is_return: 0, //是否返工
            return_num: this.checkRecordObj.returnNum, //返工数
            return_process: '', //返工工序id
            remark: this.checkRecordObj.remark, //备注
            id: this.checkRecordObj.id, //主键id
          }


          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 3, '修改验收记录数据中,请稍后...');
          //发送请求
          api.updateMesMainRecord(data).then(res => {
            // 关闭loading框
            this.loading.close();
            //判断返回结果
            if (res.code == 200) {
              this.fileList = []; //清空回显图片
              this.$message({
                type: 'success',
                message: '修改成功',
                duration: this.elDuration
              })
              //获取最新列表信息
              this.getProcessDetailData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
          //清空回显照片
          this.fileList = [];
          // 关闭修改弹框
          this.show_checkrecordform = false;
        }
      },

      //验收功能
      reportForAcceptance(data){
        if(data.check_state==1){
          this.$message({
            type: 'warning',
            message: "该单已经验收，请不要重复验收",
            duration: this.elDuration
          })
          return
        }
        // if(data.check_state==2){
        //   this.$message({
        //     type: 'warning',
        //     message: "该单已经返工，请不要重复验收",
        //     duration: this.elDuration
        //   })
        //   return
        // }
       let mesData={
         id:data.id
       }
       this.$confirm("本次报工合格数为:"+data.qualified_num+ "；报废数为:"+data.scrap_num+"；合计用时:"+data.total_time+"，确认本次报工合格后，本次数据将不能更改。", "提示", {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       }).then(()=>{
         api.reportForAcceptance(mesData).then(res=>{
           if (res.code == 200) {
             this.$message({
               type: 'success',
               message: '验收成功',
               duration: this.elDuration
             })
             //获取最新列表信息
             this.getProcessDetailData();
           } else {
             this.$message({
               type: 'error',
               message: res.data,
               duration: this.elDuration
             })
             return
           }
         })
       }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消验收操作',
            duration: this.elDuration
          });
        })

      },

      /* 获取已选验收人员信息 */
      getSelUserData(data) {
        //获取所选员工信息
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //获取验收人员信息
        this.checkRecordObj.checkUser = result.nameShowStr;
        this.checkRecordObj.checkUserId = result.idStr;
      },

      /* 获取时间格式 */
      getTimeFormat(date, filed) {
        this.checkRecordObj[filed] = this.commonJsExtend.getDateTime(date, 2);
      },

      /* 显示选择验收人员弹框 */
      getUserData() {
        //加载员工选择弹框
        this.show_personnelBox = true;
        //显示员工选择弹框
        this.SHOW_PERSONNELBOX(true);
      },

      /* 关闭弹窗 */
      closeCheckBox() {
        this.show_checkrecordform = false;
        this.fileList = []; //清空回显照片数据
      },

      /* 获取工序汇报详情列表*/
      getProcessDetailData() {
        //定义公共参数
        let data = {
          pageIndex: this.queryData.currentPage, //当前页
          pageSize: this.queryData.pageSize, //页面大小
          mesMainId: this.mesDetailData.id, //生产单id
          deleteFlag: 0, //未删除的
        }

        //判断是否有工序条件
        if (this.queryData.processId != -1) {
          data.mesProcessId = this.queryData.processId;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 4, '汇报信息获取中,请稍候...');
        //发送请求
        api.findMesProcessListByCondition(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取数据
            this.checkRecordData = res.data.rows;
            //获取总页数
            this.queryData.total = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 删除验收记录*/
      daleteCheckCord(id) {
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#checkRecordBox", 2, '删除验收记录数据中,请稍后...');
          //发送请求
          api.modifyMesMainRecordDisable({
            id
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '验收记录删除成功!',
                duration: this.elDuration
              });
              this.getProcessDetailData(); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          })
        })
      },

      //文件上传成功后执行函数
      uploadSuccess(response, file, fileList) {
        this.filePath = response.data; //后端返回的路径
        this.fileName = file.name; //文件名
        this.$message({
          type: 'success',
          message: '文件上传成功',
          duration: 1500
        })
      },

      //文件上传验证信息
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },

      //图片放大方法
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        console.log(file.url);
        this.dialogVisible = true;
      },

      //删除图片
      handleRemove(file, fileList) {
        this.$confirm('您确定要删除这张图片吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let uploadFile = this.$refs.upload.uploadFiles;
          uploadFile.forEach((item, index) => {
            if (file.uid == item.uid) {
              this.$refs.upload.uploadFiles.splice(index, 1)
            }
          })
          //删除图片后清空传给后端的地址
          this.filePath = '';
        }).catch(() => {})
      },

      // 下载
      handleDownload(file) {
        window.open(file.url)
      },
    },
    components: {
      personnelList,
      reportCreate
    }
  }
</script>

<style lang="less">
  .list_page {
    padding: 5px;
    text-align: center;
    border: 1px solid @borderColor;
  }
</style>
