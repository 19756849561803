<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划详情页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="procuctionplan_detailsAll billingdetail">
    <!-- 头部操作 -->
    <!-- <div class="procuctionplan_detail_head billingdetail_headoperate" id="mes_detail">
      <ul class="list">
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              打印<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">生产单</el-dropdown-item>
              <el-dropdown-item command="1">领料单</el-dropdown-item>
              <el-dropdown-item command="2">退料单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              添加工序<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">自生产</el-dropdown-item>
              <el-dropdown-item command="1">委外加工</el-dropdown-item>
              <el-dropdown-item command="2">根据权限</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              领料<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">采购需求</el-dropdown-item>
              <el-dropdown-item command="1">生产领料</el-dropdown-item>
              <el-dropdown-item command="2">生产退料</el-dropdown-item>
              <el-dropdown-item command="3">用料登记</el-dropdown-item>
              <el-dropdown-item command="4">添加物料</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              工序验收<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">生产中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              质检<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">生产完</el-dropdown-item>
              <el-dropdown-item command="1">质检中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              入库<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">质检完</el-dropdown-item>
              <el-dropdown-item command="1">入库中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              发货<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">入库完</el-dropdown-item>
              <el-dropdown-item command="1">发货中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              付款<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">付款完</el-dropdown-item>
              <el-dropdown-item command="1">付款中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              任务完成<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">付完款</el-dropdown-item>
              <el-dropdown-item command="1">完成中</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div> -->

    <!-- 任务进度 -->
    <div class="procuctionplan_detail_progress">
      <el-steps :active="mesDetailData.mes_state" align-center finish-status="success">
        <el-step title="生成任务"></el-step>
        <el-step title="审核中"></el-step>
        <el-step title="已审核"></el-step>
        <el-step title="领料中"></el-step>
        <el-step title="备料中"></el-step>
        <el-step title="生产中"></el-step>
        <el-step title="质检中"></el-step>
        <el-step title="已入库"></el-step>
        <el-step title="已发货"></el-step>
        <el-step title="已付款"></el-step>
        <el-step title="已完结"></el-step>
      </el-steps>

      <!-- <el-collapse-transition>
        <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
          <el-steps direction="vertical" :active="1">
            <el-step title="步骤 1"></el-step>
            <el-step title="步骤 2"></el-step>
            <el-step title="步骤 3"></el-step>
          </el-steps>
        </div>
      </el-collapse-transition> -->

      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="2">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>


      <div class="step_itemBox stepItem_ysh" v-show="show_ysh">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>

      <div class="step_itemBox stepItem_llz" v-show="show_llz">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <!-- <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div>
      <div class="step_itemBox stepItem_scrw" v-show="show_scrw">
        <el-steps direction="vertical" :active="1">
          <el-step title="步骤 1"></el-step>
          <el-step title="步骤 2"></el-step>
          <el-step title="步骤 3"></el-step>
        </el-steps>
      </div> -->

      <!-- <div class="step_itemBox" v-show="show_scrw">
        <ul>
          <li>分进程1</li>
          <li>分进程2</li>
          <li>分进程3</li>
        </ul>
      </div> -->
    </div>

    <!-- 基本信息 -->
    <div class="procuctionplan_detail_message billingcreate_form">
      <ul class="form_ul">
        <li>
          <div class="form_title">生产单号:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.bills_no" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">订单号:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.relevance_order" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">订划数:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.plan_num" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">计划完工:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.complete_data_plan" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">产品编号:</div>
          <!-- 使用对象.对象时需写一层判断 -->
          <div class="form_date" v-if="mesDetailData.productInfo">
            <input type="text" v-model="mesDetailData.productInfo.product_code" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
       <li>
          <div class="form_title">产品名称:</div>
          <div class="form_date" v-if="mesDetailData.productInfo">
            <input type="text" v-model="mesDetailData.productInfo.name" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">规格型号:</div>
          <div class="form_date" v-if="mesDetailData.productInfo">
            <input type="text" v-model="mesDetailData.productInfo.specifications" placeholder="暂无数据"
              disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">条型码:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">验收合格:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.acceptNum" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">已入库数:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.storeNum" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">实际完工:</div>
          <div class="form_date">
            <input type="text" v-model="mesDetailData.finishNum" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">委外厂家:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <!-- <li>
          <div class="form_title">所需费用:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">已付费用:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">未付费用:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li>
        <li>
          <div class="form_title">合计成本:</div>
          <div class="form_date">
            <input type="text" placeholder="暂无数据" disabled="disabled">
          </div>
        </li> -->
      </ul>
    </div>

    <!-- 详情子菜单 -->
    <div class="procuctionplan_detail_tab">
      <el-tabs v-model="proDetailTab" type="border-card" :before-leave="beforeLeave">
        <el-tab-pane label="物料管理" name="0">
          <detailMaterial :mesDetailData="mesDetailData"></detailMaterial>
        </el-tab-pane>
        <!-- <el-tab-pane label="物料管理" name="1">
          <detailOutinventory :mesDetailData="mesDetailData"></detailOutinventory>
        </el-tab-pane> -->
        <el-tab-pane label="生产工序" name="2">
          <detailProcess :mesDetailData="mesDetailData"></detailProcess>
        </el-tab-pane>
        <el-tab-pane label="工艺方案" name="3">
          <detailTechnology :mesDetailData="mesDetailData"></detailTechnology>
        </el-tab-pane>
        <el-tab-pane label="验收记录" name="4">
          <detailCheckrecord :mesDetailData="mesDetailData"></detailCheckrecord>
        </el-tab-pane>
        <el-tab-pane label="入库产品" name="5">
          <detailIntinventory :mesDetailData="mesDetailData"></detailIntinventory>
        </el-tab-pane>
        <el-tab-pane label="加工费用" name="6">
          <detailCharges :mesDetailData="mesDetailData"></detailCharges>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="7">
          <detailOperatelog :mesDetailData="mesDetailData"></detailOperatelog>
        </el-tab-pane>
        <el-tab-pane label="成本核算" name="8">
          <detailCostAccounting :mesDetailData="mesDetailData"></detailCostAccounting>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入子菜单各组件
  import detailMaterial from './detail_componentItem/detail_material.vue'; //所需物料组件
  import detailProcess from './detail_componentItem/detail_process.vue'; //生产工序组件
  import detailTechnology from './detail_componentItem/detail_technology.vue'; //工艺方案组件
  import detailCheckrecord from './detail_componentItem/detail_checkrecord.vue'; //验收记录组件
  import detailOutinventory from './detail_componentItem/detail_outInventory.vue'; //出库物料组件
  import detailIntinventory from './detail_componentItem/detail_inInventory.vue'; //入库产品组件
  import detailCharges from './detail_componentItem/detail_charges.vue'; //加工费用组件
  import detailOperatelog from './detail_componentItem/detail_operateLog.vue'; //操作日志组件
  import detailCostAccounting from './detail_componentItem/detail_costAccounting.vue'; //成本核算组件

  // 导入接口请求
  import api from '@/network/production/production.js'

  export default {
    name: 'productplan_details',
    data() {
      return {
        // 测试用
        show_scrw: false,
        show_ysh: false,
        show_llz: false,

        //当前组件数据
        mesState: 0, //生产单状态
        proDetailTab: "0", //tab当前选中
        mesDetailData: {}, //生产详情主表数据

        //loading框
        loading: '',
      }
    },
    created() {

    },
    mounted() {
      //初始化生产详情界面数据
      this.initMesDetailData();

      //初始化标签页
      var that=this
      setTimeout(function(){
              that.initmesDetailTable()
            },2000)
    },
    computed:{
      //路由传入的生产单id
      mesDetailId(){
        return this.$route.query.mesId;
      },
      mesDetailTable(){
        return this.$route.query.mesproDetailTab;
      }
    },
    watch: {
      //监听生产详情页下标
      proDetailTab(newVal) {
        this.PRO_DETAIL_TAB_INDEX(newVal);
      },
    },
    methods: {
      ...mapMutations([
        'PRO_DETAIL_TAB_INDEX', //生产详情页tab下标
      ]),

      /* 可删(暂未开放) */
      beforeLeave(){
        // this.$message({
        //   type:'warning',
        //   message:'该功能暂未开放!',
        //   duration:this.elDuration
        // })
        // return false;
      },

      /* 初始化生产单详情界面数据 */
      initMesDetailData() {
        //定义传入后端的数据
        let data = {
          id: this.mesDetailId
        }
        // 发送请求
        api.findMesMainById(data).then(res => {
          if (res.code == 200) {
            //获取详情页数据
            // this.$set(this.mesDetailData,res.data)
            this.mesDetailData = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      //初始话标签页
      initmesDetailTable(){
        console.log(this.mesDetailTable)
        if(!!this.mesDetailTable){
          this.proDetailTab=this.mesDetailTable
        }

      },
      //测试用
      aaa() {
        this.show_scrw = !this.show_scrw;
        this.show_ysh = false;
        this.show_llz = false;
      },
      aaa1() {
        this.show_scrw = false;
        this.show_ysh = !this.show_ysh;
        this.show_llz = false;
      },
      aaa2() {
        this.show_scrw = false;
        this.show_ysh = false;
        this.show_llz = !this.show_llz;
      },
    },
    destroyed(){
     this.PRO_DETAIL_TAB_INDEX("0");
    },
    components: {
      detailMaterial, //所需物料
      detailProcess, //生产工序
      detailTechnology, //工艺方案
      detailCheckrecord, //验收记录
      detailOutinventory, //出库物料
      detailIntinventory, //入库产品
      detailCharges, //加工费用
      detailOperatelog, //操作日志
      detailCostAccounting,//成本核算
    }
  }
</script>


<!-- 生产任务组价公共样式 -->
<style lang="less">
  .procuctionplan_detailsAll {
    height: calc(100% - 20px);

    // border: 1px solid black;
    // 基本信息
    .procuctionplan_detail_message {
      // border: 1px solid black;
    }

    // 任务进度
    .procuctionplan_detail_progress {
      position: relative;
      padding: 30px 25%;
      border-bottom: 1px solid @borderColor;

      // border: 1px solid red;
      // 分进度内容
      .step_itemBox {
        width: 100px;
        height: 200px;
        z-index: 1;
        padding: 10px 10px;
        background: white;
        border-radius: 5px;
        box-shadow: darkgrey 1px 1px 2px 2px; //边框阴影

        // border: 1px solid black;
        ul {
          li {
            padding: 10px 0;
            border: 1px solid black;
          }
        }
      }

      .stepItem_scrw {
        position: absolute;
        left: 25%;
      }

      .stepItem_ysh {
        position: absolute;
        left: 30%;
      }

      .stepItem_llz {
        position: absolute;
        left: 35%;
      }
    }

    // 详情子菜单
    .procuctionplan_detail_tab {
      height: 69%;
      padding: 0 20px;

      // border: 1px solid red;
      // 子菜单头部
      .detailtab_head {
        padding-bottom: 10px;
        // border: 1px solid black;
      }

      // 子菜单表格(有操作按钮)
      .detailtab_table {
        height: 50vh;
        // border: 1px solid red;
      }
    }
  }
</style>
